import ChatUtils from './ChatUtils';
import Utils from '../Utils';
import config from '../config';
import ChatHelpers from './Helpers';
import { XMPPClient } from './xmpp';
import { Chat } from '../types';

export default class ContactList {
  readonly xmlns: string = 'jabber:iq:roster';
  public contacts: Chat.ContactList = {};
  public helpers: ChatHelpers;
  public xmppClient: XMPPClient;
  public stanzasCallbacks: Chat.StanzasCallbacks;

  constructor(options: { helpers: ChatHelpers; xmppClient: XMPPClient; stanzasCallbacks: Chat.StanzasCallbacks }) {
    this.helpers = options.helpers;
    this.xmppClient = options.xmppClient;
    this.stanzasCallbacks = options.stanzasCallbacks;
  }

  public async get(): Promise<Chat.ContactList> {
    return new Promise((resolve, _reject) => {
      const contacts: Chat.ContactList = {};
      const stanzaId = ChatUtils.getUniqueId('getRoster');
      const iqStanza = ChatUtils.createIqStanza({
        type: 'get',
        from: this.helpers.userCurrentJid,
        id: stanzaId,
      });

      iqStanza.c('query', { xmlns: this.xmlns });

      this.stanzasCallbacks[stanzaId] = (stanza) => {
        const items = ChatUtils.getAllElements(stanza, 'query');

        for (let i = 0, len = items.length; i < len; i++) {
          const userId = this.helpers.getUserIdFromJID(ChatUtils.getAttr(items[i], 'jid'));
          const ask = ChatUtils.getAttr(items[i], 'ask');
          const subscription = ChatUtils.getAttr(items[i], 'subscription');
          const name = ChatUtils.getAttr(items[i], 'name');
          const isUniqName = `${userId}-${config.creds.appId}` !== name;

          contacts[userId] = {
            subscription: subscription,
            ask: ask || null,
            name: isUniqName ? name : null,
          };
        }

        resolve(contacts);
      };

      this.xmppClient.sendOnline(iqStanza);
    });
  }

  public async add(params: Chat.ContactListItem | number): Promise<void> {
    return new Promise((resolve, _reject) => {
      const userId = typeof params === 'object' ? params.userId : params;
      const userName = typeof params === 'object' ? params.name : null;
      const userJid = this.helpers.jidOrUserId(userId);
      const stanzaId = ChatUtils.getUniqueId('addContactInRoster');
      const iqStanza = ChatUtils.createIqStanza({
        type: 'set',
        from: this.helpers.userCurrentJid,
        id: stanzaId,
      });

      iqStanza.c('query', { xmlns: this.xmlns }).c('item', { jid: userJid, name: userName });

      this.contacts[userId] = {
        subscription: 'none',
        ask: 'subscribe',
        name: userName,
      };

      this.stanzasCallbacks[stanzaId] = () => {
        this.sendSubscriptionPresence({
          jid: userJid,
          type: 'subscribe',
        });

        resolve();
      };

      this.xmppClient.sendOnline(iqStanza);
    });
  }

  public async confirm(params: Chat.ContactListItem | number): Promise<void> {
    const userId = typeof params === 'object' ? params.userId : params;
    const userJid = this.helpers.jidOrUserId(userId);

    this.sendSubscriptionPresence({
      jid: userJid,
      type: 'subscribed',
    });

    if (config.chat.contactList.subscriptionMode.mutual) {
      await this.add(params);
    }
  }

  public async reject(userId: number): Promise<void> {
    return new Promise((resolve, _reject) => {
      const userJid = this.helpers.jidOrUserId(userId);

      this.contacts[userId] = {
        subscription: 'none',
        ask: null,
      };

      this.sendSubscriptionPresence({
        jid: userJid,
        type: 'unsubscribed',
      });

      resolve();
    });
  }

  public async updateName(params: Chat.ContactListItem): Promise<void> {
    return new Promise((resolve, reject) => {
      const { userId, name = null } = params;
      const userJid = this.helpers.jidOrUserId(userId);
      const stanzaId = ChatUtils.getUniqueId('updateContactInRoster');

      if (Utils.isObject(this.contacts[userId])) {
        this.contacts[userId].name = name;
      } else {
        reject('No contact exists with provided user id');
        return;
      }

      const iqStanza = ChatUtils.createIqStanza({
        type: 'set',
        from: this.helpers.userCurrentJid,
        id: stanzaId,
      });

      iqStanza.c('query', { xmlns: this.xmlns }).c('item', { jid: userJid, name: name });

      this.stanzasCallbacks[stanzaId] = (element) => {
        ChatUtils.getAttr(element, 'type') === 'result' ? resolve() : reject(element);
      };

      this.xmppClient.sendOnline(iqStanza);
    });
  }

  public async remove(userId: number): Promise<void> {
    return new Promise((resolve, _reject) => {
      const userJid = this.helpers.jidOrUserId(userId);
      const stanzaId = ChatUtils.getUniqueId('removeContactInRoster');
      const iqStanza = ChatUtils.createIqStanza({
        type: 'set',
        from: this.helpers.userCurrentJid,
        id: stanzaId,
      });

      iqStanza.c('query', { xmlns: this.xmlns }).c('item', { jid: userJid, subscription: 'remove' });

      this.stanzasCallbacks[stanzaId] = () => {
        delete this.contacts[userId];
        resolve();
      };

      this.xmppClient.sendOnline(iqStanza);
    });
  }

  public sendSubscriptionPresence(params: Chat.ContactListSubscriptionPresence): void {
    const presenceParams = {
      to: params.jid,
      type: params.type,
    };

    const presenceStanza = ChatUtils.createPresenceStanza(presenceParams);

    this.xmppClient.sendOnline(presenceStanza);
  }
}
