import WebRTCSession from '../videocalling/WebRTCSession';
import WebRTCSignalingProvider from '../videocalling/WebRTCSignalingProvider';

export const SignalingModuleIdentifier = 'WebRTCVideoChat';

export enum SignalingType {
  CALL = 'call',
  ACCEPT = 'accept',
  REJECT = 'reject',
  STOP = 'hangUp',
  RESTART = 'iceRestart',
  RESTART_ACCEPT = 'iceRestartAccept',
  CANDIDATE = 'iceCandidates',
}
export enum SessionConnectionState {
  UNDEFINED = 0,
  CONNECTING = 1,
  CONNECTED = 2,
  FAILED = 3,
  DISCONNECTED = 4,
  CLOSED = 5,
  COMPLETED = 6,
}
export enum SessionState {
  NEW = 1,
  ACTIVE = 2,
  HUNGUP = 3,
  REJECTED = 4,
  CLOSED = 5,
}
export enum PeerConnectionState {
  NEW = 1,
  CONNECTING = 2,
  CHECKING = 3,
  CONNECTED = 4,
  DISCONNECTED = 5,
  FAILED = 6,
  CLOSED = 7,
  COMPLETED = 8,
}
export enum CallType {
  VIDEO = 1,
  AUDIO = 2,
}
export enum CallsEvent {
  CALL = 'call',
  ACCEPT = 'accept',
  REJECT = 'reject',
  HUNG_UP = 'hang-up',
  INVALID = 'invalid',
  NOT_ANSWER = 'not-answer',
  REMOTE_STREAM = 'remote-stream',
  CONNECTION_STATE = 'connection-state',
  CLOSE = 'close',
  STATS_REPORT = 'stats-report',
  DEVICES = 'devices',
}

export namespace ConnectyCubeCalls {
  export type UserInfo = {
    maxBandwidth?: number;
    bandwidth?: number;
    [key: string]: any;
  };
  export type ExtensionParams = {
    callType?: CallType;
    callerID?: number;
    iceCandidates?: RTCIceCandidateInit[] | any[];
    opponentsIDs?: number[];
    userInfo?: UserInfo;
    sessionID?: string;
    [key: string]: any;
  };
  export type WebRTCSessionParams = {
    ID: string | null;
    initiatorID: number;
    opponentsIDs: number[];
    callType: CallType;
    signalingProvider: WebRTCSignalingProvider;
    currentUserID: number;
    maxBandwidth: number;
  };
  // Listeners
  export type OnCallListener = (session: WebRTCSession, userInfo: UserInfo) => void;
  export type OnAcceptCallListener = (session: WebRTCSession, userID: number, userInfo: UserInfo) => void;
  export type OnRejectCallListener = (session: WebRTCSession, userID: number, userInfo: UserInfo) => void;
  export type OnStopCallListener = (session: WebRTCSession, userID: number, userInfo: UserInfo) => void;
  export type OnInvalidEventsListener = (session: WebRTCSession, userID: number, userInfo: UserInfo) => void;
  export type OnUserNotAnswerListener = (session: WebRTCSession, userID: number) => void;
  export type OnRemoteStreamListener = (session: WebRTCSession, userID: number, stream: MediaStream) => void;
  export type OnSessionConnectionStateChangedListener = (
    session: WebRTCSession,
    userID: number,
    connectionState: SessionConnectionState
  ) => void;
  export type OnSessionCloseListener = (session: WebRTCSession) => void;
  export type OnCallStatsReportListener = (session: WebRTCSession, userID: number, stats: any, error: Error) => void;
  export type OnDevicesChangeListener = (this: MediaDevices, ev: Event) => any;
  export type Listeners =
    | OnCallListener
    | OnAcceptCallListener
    | OnRejectCallListener
    | OnStopCallListener
    | OnInvalidEventsListener
    | OnUserNotAnswerListener
    | OnRemoteStreamListener
    | OnSessionConnectionStateChangedListener
    | OnSessionCloseListener
    | OnCallStatsReportListener
    | OnDevicesChangeListener;
}
