declare global {
  interface Window {
    adapter: any;
  }
}

export const base64Encode = (str: string): string =>
  isBrowser
    ? btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_, p1) => String.fromCharCode(parseInt(p1, 16))))
    : Buffer.from(str).toString('base64');

export const sdkVersion = 'define_sdk_version_replacer';
export const isBrowser: boolean = typeof window === 'object' && typeof document === 'object';
export const isNodeJS: boolean =
  typeof process === 'object' && typeof process.versions === 'object' && !!process.versions.node;
export const isReactNative: boolean = false;
export const isExpo: boolean = false;

export const fetchImpl = isNodeJS ? require('node-fetch') : fetch;
export const formDataImpl = isNodeJS ? require('form-data') : FormData;

export const navigator = isBrowser ? window.navigator : undefined;
export const mediaDevices = isBrowser && navigator ? navigator.mediaDevices : undefined;
export const MediaStream = isBrowser ? window.MediaStream : undefined;
export const MediaStreamTrack = isBrowser ? window.MediaStreamTrack : undefined;
export const RTCIceCandidate = isBrowser ? window.RTCIceCandidate : undefined;
export const RTCPeerConnection = isBrowser ? window.RTCPeerConnection : undefined;
export const RTCRtpReceiver = isBrowser ? window.RTCRtpReceiver : undefined;
export const RTCRtpSender = isBrowser ? window.RTCRtpSender : undefined;
export const RTCSessionDescription = isBrowser ? window.RTCSessionDescription : undefined;

export const isWebRTCAvailable: boolean = isBrowser ? Boolean(mediaDevices && window?.RTCPeerConnection) : false;

export const adapter = isBrowser ? window.adapter : undefined;
