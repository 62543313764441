import { Client as _Client, jid as _jid, xml as _xml } from '@xmpp/client-core';
import { Element } from '@xmpp/xml';
import _connection from '@xmpp/connection';
import _reconnect from '@xmpp/reconnect';
import _websocket from '@xmpp/websocket';
import _middleware from '@xmpp/middleware';
import _streamFeatures from '@xmpp/stream-features';
import _iqCaller from '@xmpp/iq/caller';
import _iqCallee from '@xmpp/iq/callee';
import _resolve from '@xmpp/resolve';
import _sasl from '@xmpp/sasl';
import _resourceBinding from '@xmpp/resource-binding';
import _sessionEstablishment from '@xmpp/session-establishment';
import anonymous from '@xmpp/sasl-anonymous';
import plain from '@xmpp/sasl-plain';
import Utils from '../Utils';

export interface Options extends _connection.Options {
  resource?: _resourceBinding.Resource | undefined;
  credentials?: _sasl.Credentials | undefined;
  username?: string | undefined;
  password?: string | undefined;
}

export interface XMPPClient extends _Client {
  entity: _Client;
  reconnect: _reconnect.Reconnect<_Client>;
  websocket: any;
  middleware: _middleware.Middleware<_Client>;
  streamFeatures: _streamFeatures.StreamFeatures<_Client>;
  iqCaller: _iqCaller.IQCaller<_Client>;
  iqCallee: _iqCallee.IQCallee<_Client>;
  starttls: _middleware.Middleware<_Client>;
  resolve: any;
  sasl: _sasl.SASL;
  resourceBinding: any;
  sessionEstablishment: any;
  mechanisms: { [x: string]: any }[];
  options: Options;
  sendOnline: _Client['send'];
}

export function xmppClient(options: Options): XMPPClient {
  const { resource, credentials, username, password, ...params } = options;
  const { domain, service } = params;

  if (!domain && service) {
    params.domain = (service.split('://')[1] || service).split(':')[0].split('/')[0];
  }

  const entity = new _Client(params);
  const reconnect = _reconnect({ entity });
  const websocket = _websocket({ entity });
  const middleware = _middleware({ entity });
  const streamFeatures = _streamFeatures({ middleware });
  const iqCaller = _iqCaller({ middleware, entity });
  const iqCallee = _iqCallee({ middleware, entity });
  const starttls = _middleware({ entity });
  const resolve = _resolve({ entity });
  const sasl = _sasl({ streamFeatures }, credentials || { username, password });
  const resourceBinding = _resourceBinding({ iqCaller, streamFeatures }, resource);
  const sessionEstablishment = _sessionEstablishment({ iqCaller, streamFeatures });
  const mechanisms = Object.entries({ plain, anonymous }).map(([k, v]) => ({
    [k]: v(sasl),
  }));

  const sendOnline = async function (element: Element, ...args: unknown[]): Promise<void> {
    if (entity.status === 'online') {
      return entity.send.call(entity, element, ...args);
    } else {
      const message = "You are not connected to chat. Please call 'connect' function first";
      Utils.DLog('[Chat]', message);
      return Promise.reject(new Error(message));
    }
  };

  return Object.assign(entity, {
    entity,
    reconnect,
    websocket,
    middleware,
    streamFeatures,
    iqCaller,
    iqCallee,
    starttls,
    resolve,
    sasl,
    resourceBinding,
    sessionEstablishment,
    mechanisms,
    sendOnline,
  });
}

export const jid: typeof _jid = _jid;
export const xml: typeof _xml = _xml;
