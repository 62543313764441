import config from '../config';
import { navigator } from '../platform';

export default class WebRTCHelpers {
  static getUserJid(id: string | number): string {
    return `${id}-${config.creds.appId}@${config.endpoints.chat}`;
  }

  static getUserIdFromJID(jid: string): number | null {
    return jid?.includes('@') ? parseInt(jid.split('@')[0].split('-')[0]) : null;
  }

  static trace(text: string): void {
    if (config.debug) {
      console.log('[VideoChat]:', text);
    }
  }

  static traceWarning(text: string): void {
    if (config.debug) {
      console.warn('[VideoChat]:', text);
    }
  }

  static traceError(text: string): void {
    if (config.debug) {
      console.error('[VideoChat]:', text);
    }
  }

  static get getVersionFirefox(): number {
    const ua = navigator?.userAgent ?? null;
    let version = 0;
    if (ua) {
      const ffInfo = ua.match(/(?:firefox)[ \/](\d+)/i) || [];
      version = ffInfo[1] ? +ffInfo[1] : 0;
    }
    return typeof version === 'string' ? parseInt(version) : version;
  }

  static get getVersionSafari(): number {
    const ua = navigator?.userAgent ?? null;
    let version = 0;
    if (ua) {
      const sInfo = ua.match(/(?:safari)[ \/](\d+)/i) || [];
      if (sInfo.length) {
        const sVer = ua.match(/(?:version)[ \/](\d+)/i) || [];
        if (sVer) {
          version = sVer[1] ? +sVer[1] : 0;
        }
      }
    }

    return typeof version === 'string' ? parseInt(version) : version;
  }
}
