import { isWebRTCAvailable } from './platform';
import config from './config';
import AddressBookService from './AddressBook';
import AuthService from './Auth';
import ChatClient from './messaging/Chat';
import ConferenceClient from './videocalling_conference/ConferenceClient';
import DataService from './Data';
import MeetingsService from './Meetings';
import ProxyService from './Proxy';
import PushNotificationsService from './PushNotifications';
import StorageService from './Storage';
import UsersService from './Users';
import Utils from './Utils';
import WebRTCClient from './videocalling/WebRTCClient';
import WhiteboardService from './Whiteboard';
import { Config } from './types';

class ConnectyCube {
  public ConnectyCube = ConnectyCube;
  public utils!: Utils;
  public service!: ProxyService;
  public auth!: AuthService;
  public users!: UsersService;
  public storage!: StorageService;
  public pushnotifications!: PushNotificationsService;
  public data!: DataService;
  public addressbook!: AddressBookService;
  public chat!: ChatClient;
  public meeting!: MeetingsService;
  public whiteboard!: WhiteboardService;
  public videochat!: WebRTCClient;
  public videochatconference!: ConferenceClient;
  public setSession!: AuthService['setSession'];
  public getSession!: AuthService['getSession'];
  public createSession!: AuthService['createSession'];
  public destroySession!: AuthService['destroySession'];
  /**
   * @deprecated ConnectyCube.login(user) is deprecated. Use `ConnectyCube.createSession(user)` or `ConnectyCube.auth.login(user)` instead.
   */
  public login!: AuthService['login'];
  /**
   * @deprecated ConnectyCube.logout() is deprecated. Use `ConnectyCube.destroySession()` or `ConnectyCube.auth.logout()` instead.
   */
  public logout!: AuthService['logout'];

  public init(credentials: Config.Credentials, configMap: Config.Options = {}): void {
    if (configMap && typeof configMap === 'object') {
      config.set(configMap);
    }

    this.utils = Utils;
    this.service = new ProxyService();
    this.auth = new AuthService(this.service);
    this.users = new UsersService(this.service);
    this.storage = new StorageService(this.service);
    this.pushnotifications = new PushNotificationsService(this.service);
    this.data = new DataService(this.service);
    this.addressbook = new AddressBookService(this.service);
    this.chat = new ChatClient(this.service);
    this.meeting = new MeetingsService(this.service);
    this.whiteboard = new WhiteboardService(this.service);

    if (isWebRTCAvailable) {
      this.videochat = new WebRTCClient(this.chat.xmppClient, this.service);
      this.videochatconference = new ConferenceClient(this.service);
      this.chat.webrtcSignalingProcessor = this.videochat.signalingProcessor;
    }

    if (credentials.token) {
      config.creds.appId = credentials.appId;
      this.service.setSession({ token: credentials.token });
    } else {
      config.creds.appId = credentials.appId;
      config.creds.authKey = credentials.authKey;
    }

    this.setSession = this.auth.setSession;
    this.getSession = this.auth.getSession;
    this.createSession = this.auth.createSession;
    this.destroySession = this.auth.destroySession;
    this.login = this.auth.login;
    this.logout = this.auth.logout;
  }
}

export default new ConnectyCube();
