import Utils from '../Utils';
import config from '../config';
import ChatUtils from './ChatUtils';

export default class ChatHelpers {
  public xmppJID: string = '';

  public get userCurrentJid(): string | '' {
    return this.xmppJID;
  }

  public set userCurrentJid(jid: string | null) {
    this.xmppJID = jid || '';
  }

  public getUniqueId = ChatUtils.getUniqueId;

  public isNumeric(value: string): boolean {
    return /^-?\d+$/.test(value) || /^\d+\.\d+$/.test(value);
  }

  public jidOrUserId(jidOrUserId: string | number): string {
    if (typeof jidOrUserId === 'string') {
      return this.isNumeric(jidOrUserId)
        ? this.getUserJid(jidOrUserId)
        : jidOrUserId.includes('@')
          ? jidOrUserId
          : this.getRoomJidFromDialogId(jidOrUserId);
    }

    return this.getUserJid(jidOrUserId);
  }

  public typeChat(jidOrUserId: string | number): 'chat' | 'groupchat' {
    switch (typeof jidOrUserId) {
      case 'string':
        return this.isNumeric(jidOrUserId)
          ? 'chat'
          : jidOrUserId.includes('@')
            ? jidOrUserId.includes('muc')
              ? 'groupchat'
              : 'chat'
            : 'groupchat';
      case 'number':
        return 'chat';
      default:
        throw new Error('Unsupported chat type');
    }
  }

  public getUserJid(userId: string | number): string {
    return `${userId}-${config.creds.appId}@${config.endpoints.chat}`;
  }

  public getUserNickWithMucDomain(userId: string | number): string {
    return `${config.endpoints.muc}/${userId}`;
  }

  public getUserIdFromJID(jid: string = this.userCurrentJid): number | null {
    return jid?.includes('@') ? parseInt(jid.split('@')[0].split('-')[0]) : null;
  }

  public getDialogIdFromJID(jid: string): string | null {
    return jid?.includes('@') ? jid.split('@')[0].split('_')[1] : null;
  }

  public getRoomJidFromDialogId(dialogId: string): string {
    return `${config.creds.appId}_${dialogId}@${config.endpoints.muc}`;
  }

  public getRoomJid(jid: string): string {
    return `${jid}/${this.getUserIdFromJID(this.userCurrentJid)}`;
  }

  public getIdFromResource(jid: string): number | null {
    const parts = jid.split('/');
    return parts.length > 1 ? parseInt(parts.slice(1).join('/')) : null;
  }

  public getRoomJidFromRoomFullJid(jid: string): string | null {
    const parts = jid.split('/');
    return parts.length > 1 ? parts[0] : null;
  }

  public getBsonObjectId = Utils.getBsonObjectId;

  public getUserIdFromRoomJid(roomJid: string): number | null {
    const parts = roomJid.split('/');
    return parts.length > 1 ? parseInt(parts[parts.length - 1]) : null;
  }

  getDialogJid(identifier: string): string {
    return identifier.indexOf('@') > 0 ? identifier : this.getRoomJidFromDialogId(identifier);
  }
}
