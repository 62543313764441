import ProxyService from '../Proxy';
import Utils from '../Utils';
import config from '../config';
import { EmptyResponse, ForceParams, Messages } from '../types';
import { ProxyMethod, ProxyType } from '../types/proxy';

export default class MessagesService {
  public proxy: ProxyService;
  readonly route = `${config.urls.chat}/Message`;

  constructor(proxy: ProxyService) {
    this.proxy = proxy;
  }

  public async list(params: Messages.ListParams = {}): Promise<Messages.ListResponse> {
    const ajaxParams = {
      url: Utils.getUrl(this.route),
      data: params,
    };

    return this.proxy.ajax(ajaxParams);
  }

  public async create(params: Messages.CreateParams = {}): Promise<Messages.Message> {
    const ajaxParams = {
      url: Utils.getUrl(this.route),
      type: ProxyMethod.POST,
      data: params,
    };

    return this.proxy.ajax(ajaxParams);
  }

  public async update(id: string, params: Messages.UpdateParams = {}): Promise<EmptyResponse> {
    const ajaxParams = {
      type: ProxyMethod.PUT,
      dataType: ProxyType.TEXT,
      url: Utils.getUrl(this.route, id),
      data: params,
    };

    return this.proxy.ajax(ajaxParams);
  }

  public async delete(id: string, params?: ForceParams): Promise<Messages.DeleteResponse> {
    const ajaxParams = {
      url: Utils.getUrl(this.route, id),
      type: ProxyMethod.DELETE,
      dataType: ProxyType.TEXT,
      data: params,
    };

    return this.proxy.ajax(ajaxParams);
  }

  public async createSystem(params: Messages.CreateSystemParams): Promise<Messages.CreateSystemResponse> {
    const ajaxParams = {
      type: ProxyMethod.POST,
      url: Utils.getUrl(this.route, 'system'),
      data: params,
    };

    return this.proxy.ajax(ajaxParams);
  }

  public async unreadCount(params: Messages.UnreadCountParams = {}): Promise<Messages.UnreadCountResponse> {
    const data = Utils.cloneObject(params);

    if (data && data.chat_dialog_ids && Utils.isArray(data.chat_dialog_ids)) {
      data.chat_dialog_ids = data.chat_dialog_ids.join(', ');
    }

    const ajaxParams = {
      url: Utils.getUrl(this.route, 'unread'),
      data: data,
    };

    return this.proxy.ajax(ajaxParams);
  }

  public async listReactions(id: string = '_'): Promise<Messages.ListReactionsResponse> {
    const ajaxParams = {
      type: ProxyMethod.GET,
      dataType: ProxyType.JSON,
      url: Utils.getUrl(this.route, id, 'reactions'),
    };

    return this.proxy.ajax(ajaxParams);
  }

  public async addReaction(id: string, reaction: string): Promise<EmptyResponse> {
    return this.updateReaction(id, reaction);
  }

  public async removeReaction(id: string, reaction: string): Promise<EmptyResponse> {
    return this.updateReaction(id, undefined, reaction);
  }

  public async updateReaction(id: string, add?: string, remove?: string): Promise<EmptyResponse> {
    return this.putReaction(id, { add, remove });
  }

  private async putReaction(id: string, params: Messages.PutReactionParams): Promise<EmptyResponse> {
    const ajaxParams = {
      type: ProxyMethod.PUT,
      dataType: ProxyType.TEXT,
      url: Utils.getUrl(this.route, id, 'reactions'),
      data: params,
    };

    return this.proxy.ajax(ajaxParams);
  }
}
