import { sdkVersion } from './platform';
import { JanusDebugMode, ConnectyCubeConfig } from './types/config';

export class Config {
  private static instance: Config;

  public version: string = sdkVersion;
  public creds: ConnectyCubeConfig.Credentials = { appId: '', authKey: '' };
  public endpoints: ConnectyCubeConfig.Endpoints = {
    api: 'api.connectycube.com',
    chat: 'chat.connectycube.com',
    muc: 'muc.chat.connectycube.com',
  };
  public chatProtocol: ConnectyCubeConfig.ChatProtocolURLs = {
    bosh: 'https://chat.connectycube.com:5281',
    websocket: 'wss://chat.connectycube.com:5291',
    active: 2,
  };
  public chat: ConnectyCubeConfig.Chat = {
    contactList: { subscriptionMode: { mutual: true } },
    streamManagement: { enable: false },
    ping: { enable: false, timeInterval: 60 },
    reconnect: { enable: true, timeInterval: 5 },
  };
  public videochat: ConnectyCubeConfig.Videochat = {
    alwaysRelayCalls: false,
    answerTimeInterval: 60,
    dialingTimeInterval: 5,
    disconnectTimeInterval: 30,
    statsReportTimeInterval: null,
    iceServers: [
      { urls: 'stun:stun.l.google.com:19302' },
      { urls: 'stun:turn.connectycube.com' },
      {
        urls: 'turn:turn.connectycube.com:5349?transport=udp',
        username: 'connectycube',
        credential: '4c29501ca9207b7fb9c4b4b6b04faeb1',
      },
      {
        urls: 'turn:turn.connectycube.com:5349?transport=tcp',
        username: 'connectycube',
        credential: '4c29501ca9207b7fb9c4b4b6b04faeb1',
      },
    ],
  };
  public conference: ConnectyCubeConfig.Conference = {
    server: 'wss://janus.connectycube.com:8989',
    debug: JanusDebugMode.ALL,
  };
  public whiteboard: ConnectyCubeConfig.Whiteboard = { server: 'https://whiteboard.connectycube.com' };
  readonly urls: ConnectyCubeConfig.ApiRoutes = {
    session: 'session',
    login: 'login',
    users: 'users',
    chat: 'chat',
    blobs: 'blobs',
    subscriptions: 'subscriptions',
    events: 'events',
    data: 'data',
    addressbook: 'address_book',
    meetings: 'meetings',
    whiteboards: 'whiteboards',
    calls: 'calls',
    type: '.json',
  };
  public on: ConnectyCubeConfig.Listeners = {
    sessionExpired: undefined,
    xmppDataWrite: undefined,
    xmppDataRead: undefined,
  };
  public timeout: number | null = null;
  public debug: ConnectyCubeConfig.Debug = { mode: 1 };

  private constructor() {}

  public static getInstance(): Config {
    if (!Config.instance) {
      Config.instance = new Config();
    }
    return Config.instance;
  }

  public set(options: ConnectyCubeConfig.Options): void {
    if (options.endpoints && options.endpoints.chat) {
      this.endpoints.muc = `muc.${options.endpoints.chat}`;
      this.chatProtocol.bosh = `https://${options.endpoints.chat}:5281`;
      this.chatProtocol.websocket = `wss://${options.endpoints.chat}:5291`;
    }

    Object.keys(options).forEach((key) => {
      if (key !== 'set' && this.hasOwnProperty(key)) {
        const optionValue = options[key];

        if (typeof optionValue !== 'object') {
          this[key] = optionValue;
        } else {
          Object.keys(optionValue).forEach((nextKey) => {
            if (this[key]?.hasOwnProperty(nextKey)) {
              this[key][nextKey] = optionValue[nextKey];
            }
          });
        }
      }
    });
  }
}

export default Config.getInstance();
