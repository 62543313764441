import config from './config';
import ProxyService from './Proxy';
import { EmptyResponse, Users } from './types';
import { ProxyMethod, ProxyType } from './types/proxy';
import Utils from './Utils';

export default class UsersService {
  public proxy: ProxyService;
  readonly route = config.urls.users;

  constructor(proxy: ProxyService) {
    this.proxy = proxy;
  }

  public async getV2(params: Users.GetV2Params): Promise<Users.GetV2Response> {
    const ajaxParams = {
      type: ProxyMethod.GET,
      url: Utils.getUrl(this.route, 'v2'),
      useArrayQuery: true,
      data: params,
    };

    return this.proxy.ajax(ajaxParams);
  }

  public async signup(user: Users.SignUpParams, auth_key?: string): Promise<Users.UserResponse> {
    const ajaxParams = {
      authKey: auth_key ?? config.creds.authKey,
      type: ProxyMethod.POST,
      url: Utils.getUrl(this.route),
      data: { user },
    };

    return this.proxy.ajax(ajaxParams);
  }

  public async update(user: Users.UpdateParams = {}): Promise<Users.UserResponse> {
    const ajaxParams = {
      type: ProxyMethod.PUT,
      url: Utils.getUrl(this.route, this.proxy.getCurrentUserId()),
      data: { user },
    };

    return this.proxy.ajax(ajaxParams);
  }

  public async delete(): Promise<EmptyResponse> {
    const ajaxParams = {
      type: ProxyMethod.DELETE,
      url: Utils.getUrl(this.route, this.proxy.getCurrentUserId()),
      dataType: ProxyType.TEXT,
    };

    return this.proxy.ajax(ajaxParams);
  }

  public async resetPassword(email: string = ''): Promise<EmptyResponse> {
    const ajaxParams = {
      type: ProxyMethod.POST,
      url: Utils.getUrl(this.route, 'password', 'reset'),
      data: { email },
      dataType: ProxyType.TEXT,
    };

    return this.proxy.ajax(ajaxParams);
  }

  /**
   * @deprecated Use `getV2(params)` instead.
   */
  public async get(params: any): Promise<Users.OldGetResponse | Users.UserResponse> {
    let data = Utils.cloneObject(params);
    let url: string | number = '';

    const filters: string[] = [];
    const dateFields = ['created_at', 'updated_at', 'last_request_at'];
    const numberFields = ['id', 'external_user_id'];
    const extensions = {
      login: 'by_login',
      full_name: 'by_full_name',
      facebook_id: 'by_facebook_id',
      twitter_id: 'by_twitter_id',
      phone: 'phone',
      email: 'by_email',
      tags: 'by_tags',
      external: 'external',
    };

    function generateFilter(filter: any): string {
      const obj = Utils.cloneObject(filter);
      let type = dateFields.includes(obj.field) ? 'date' : typeof obj.value;

      if (Utils.isArray(obj.value)) {
        if (type === 'object') {
          type = typeof obj.value[0];
        }
        obj.value = obj.value.toString();
      }

      return [type, obj.field, obj.param, obj.value].join(' ');
    }

    if (data.order) {
      const type = data.order.field in dateFields ? 'date' : data.order.field in numberFields ? 'number' : 'string';
      data.order = [data.order.sort, type, data.order.field].join(' ');
    }

    if (data && data.filter) {
      if (Utils.isArray(data.filter)) {
        data.filter.forEach((filter) => {
          filters.push(generateFilter(filter));
        });
      } else {
        filters.push(generateFilter(data.filter));
      }
      data.filter = filters;
    }

    if (typeof data === 'number') {
      url = data;
      data = undefined;
    } else {
      for (const key in extensions) {
        if (data[key]) {
          url = extensions[key];

          if (key === extensions.external) {
            url += `/${data[extensions.external]}`;
            data = undefined;
          }

          break;
        }
      }
    }

    const ajaxParams = {
      type: ProxyMethod.GET,
      url: Utils.getUrl(this.route, url),
      data,
    };

    return this.proxy.ajax(ajaxParams);
  }
}
