import config from '../config';
import { xml } from './xmpp';
import { Chat } from '../types';

export default class ChatUtils {
  static buildUserJid(params: Chat.UserJIDParams = {}): string | void {
    const { userId, resource, jid } = params;
    const appId = config.creds.appId;
    const url = config.endpoints.chat;

    return userId ? `${userId}-${appId}@${url}${resource ? `/${resource}` : ''}` : jid;
  }

  static buildUserJidLocalPart(userId: string | number): string {
    return `${userId}-${config.creds.appId}`;
  }

  static createMessageStanza(attrs?: string | { [attrName: string]: any }): Chat.XmlElement {
    return xml('message', attrs);
  }

  static createIqStanza(attrs?: string | { [attrName: string]: any }): Chat.XmlElement {
    return xml('iq', attrs);
  }

  static createPresenceStanza(attrs?: string | { [attrName: string]: any }): Chat.XmlElement {
    return xml('presence', attrs);
  }

  static createNonza(name: string, attrs?: string | { [attrName: string]: any }): Chat.XmlElement {
    return xml(name, attrs);
  }

  static getAttr(element: Chat.XmlElement, name: string): any {
    return element?.getAttr(name) || element?.attrs[name] || null;
  }

  static getElement(element: Chat.XmlElement, name: string): Chat.XmlElement | undefined {
    return element?.getChild(name);
  }

  static getName(element: Chat.XmlElement): string | null {
    return element?.getName() || null;
  }

  static getText(element: Chat.XmlElement): string | null {
    return element?.getText() || null;
  }

  static getChildElements(element: Chat.XmlElement): Chat.XmlElement[] {
    return element?.getChildElements() || [];
  }

  static isErrorStanza(element: Chat.XmlElement): boolean {
    return !!element?.getChild('error');
  }

  static getAllElements(element: Chat.XmlElement, name: string): Chat.XmlElement[] {
    return element?.getChildren(name) || [];
  }

  static getElementText(element: Chat.XmlElement, name: string): string | null {
    return element?.getChildText(name) || null;
  }

  static getElementTreePath(element: Chat.XmlElement, elementsPath: string[]): Chat.XmlElement | undefined {
    return elementsPath.reduce<Chat.XmlElement | undefined>(
      (prevElement: Chat.XmlElement, name: string) =>
        prevElement ? ChatUtils.getElement(prevElement, name) : prevElement,
      element
    );
  }

  static assignObjectToXml(element: Chat.XmlElement, obj: { [key: string]: any }, name: string): Chat.XmlElement {
    element = element.c(name);

    Object.keys(obj).forEach((key: string) => {
      if (typeof obj[key] === 'object') {
        ChatUtils.assignObjectToXml(element, obj[key], key);
      } else {
        element = element.c(key).t(obj[key]).up();
      }
    });

    element = element.up();

    return element;
  }

  static assignExtraParamsToXml(element: Chat.XmlElement, extension: { [key: string]: any }): Chat.XmlElement {
    let extraParams = ChatUtils.getElement(element, 'extraParams');

    Object.keys(extension).forEach((key) => {
      if (key === 'attachments') {
        (extension[key] as any[]).forEach((attachment: any) => {
          extraParams!.c('attachment', attachment).up();
        });
      } else if (typeof extension[key] === 'object') {
        ChatUtils.assignObjectToXml(extraParams!, extension[key], key);
      } else {
        extraParams!.c(key).t(extension[key]).up();
      }
    });

    element.up();

    return element;
  }

  static assignXmlToObject(element: Chat.XmlElement, obj: any): any {
    const { children, name } = element;
    const item: { [key: string]: any } = { [name]: {} };

    children.forEach((node: Chat.XmlNode) => {
      if (typeof node === 'string') {
        item[name] = node;
      } else if (node.children.length > 0) {
        ChatUtils.assignXmlToObject(node, item[name]);
      } else {
        item[name][node.name] = ChatUtils.getText(node);
      }
    });

    return Object.assign(obj, item);
  }

  static parseExtraParams(element: Chat.XmlElement): { extension: any; dialogId: string | null } | null {
    if (!element) {
      return null;
    }

    const extension: any = { attachments: [] };
    let dialogId: string | null = null;

    element.children.forEach((node: Chat.XmlNode) => {
      if (typeof node === 'string') {
        return;
      }

      if (node.name === 'attachment') {
        const attachment: any = {};

        Object.keys(node.attrs).forEach((key: string) => {
          attachment[key] = key === 'size' ? parseInt(node.attrs.size) : node.attrs[key];
        });

        extension.attachments.push(attachment);
      } else if (node.name === 'dialog_id') {
        dialogId = ChatUtils.getElementText(element, 'dialog_id');
        extension.dialog_id = dialogId;
      }

      if (node.children.length) {
        ChatUtils.assignXmlToObject(node, extension);
      }
    });

    if (extension.attachments.length === 0) {
      delete extension.attachments;
    }
    if (extension.moduleIdentifier) {
      delete extension.moduleIdentifier;
    }

    return { extension, dialogId };
  }

  static buildErrorFromXMPPErrorStanza(errorStanza: Chat.XmlElement): { code: number; info: string | null } {
    const errorElement = ChatUtils.getElement(errorStanza, 'error');
    const code = errorElement ? parseInt(ChatUtils.getAttr(errorElement, 'code')) : 0;
    const info = errorElement ? ChatUtils.getElementText(errorElement, 'text') : null;
    return { code: code, info: info };
  }

  static getUniqueId(suffix?: any): string {
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0;
      const v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });

    if (typeof suffix === 'string' || typeof suffix === 'number') {
      return `${uuid}:${suffix}`;
    } else {
      return uuid;
    }
  }

  static parseReactions(element: Chat.XmlElement): { add: string; remove: string } {
    const reactions = ChatUtils.getChildElements(element).map((node: Chat.XmlElement) => ({
      add: ChatUtils.getAttr(node, 'add') === 'true',
      remove: ChatUtils.getAttr(node, 'remove') === 'true',
      reaction: ChatUtils.getAttr(node, 'type'),
    }));

    return reactions.reduce(
      (items, { add, remove, reaction }) => {
        if (add) {
          items['add'] = reaction;
        } else if (remove) {
          items['remove'] = reaction;
        }
        return items;
      },
      {} as { add: any; remove: any }
    );
  }
}
