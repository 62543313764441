import ConferenceSession from '../videocalling_conference/ConferenceSession';

export enum CallType {
  VIDEO = 'video',
  AUDIO = 'audio',
}
export enum DeviceInputType {
  VIDEO = 'videoinput',
  AUDIO = 'audioinput',
}
export enum JanusEvent {
  PARTICIPANT_JOINED = 'participant_joined',
  PARTICIPANT_LEFT = 'participant_left',
  LOCAL_STREAM = 'local_stream',
  REMOTE_STREAM = 'remote_stream',
  REMOTE_TRACKS_UPDATED = 'remote_tracks_updated',
  DATA_CHANNEL_OPEN = 'data_channel_open',
  DATA_CHANNEL_MESSAGE = 'data_channel_message',
  ERROR = 'error',
}
export enum JanusMediaTrackReason {
  CREATED = 'created',
  ENDED = 'ended',
  MUTE = 'mute',
  UNMUTE = 'unmute',
}
export enum ConferenceEvent {
  JOIN = 'join',
  LEFT = 'left',
  SLOW_LINK = 'slow-link',
  REMOTE_TRACKS_UPDATED = 'remote-tracks',
  REMOTE_STREAM = 'remote-stream',
  REMOTE_CONNECTION_STATE = 'remote-connection-state',
  DATA_CHANNEL_OPENED = 'data-channel-opened',
  DATA_CHANNEL_MESSAGE = 'data-channel-message',
  SESSION_CONNECTION_STATE = 'session-connection-state',
  ERROR = 'error',
}

export namespace ConnectyCubeJanus {
  export interface SuccessErrorCallbacks {
    success?: (...args: any[]) => void;
    error?: (error: any) => void;
  }
  export interface InitCallbacks extends SuccessErrorCallbacks {
    destroyed?: () => void;
    timeoutSessionCallback?: () => void;
  }
  export interface PluginCallbacks extends SuccessErrorCallbacks {
    localStream?: MediaStream;
    displayName?: string;
    iceState?: (iceState: any) => void;
    slowLink?: (uplink: any, nacks: any) => void;
    consentDialog?: (on: any) => void;
    mediaState?: (medium: any, on: any) => void;
    webrtcState?: (on: any) => void;
    oncleanup?: () => void;
  }
  export interface RequestCallbacks extends SuccessErrorCallbacks {
    displayName?: string;
  }
  // Listeners
  export type OnParticipantJoinedListener = (
    session: ConferenceSession,
    userId: number,
    userName: string,
    isExist: boolean
  ) => void;
  export type OnParticipantLeftListener = (session: ConferenceSession, ...args: any[]) => void;
  export type OnSlowLinkListener = (session: ConferenceSession, userId: number | null, uplink: any, nacks: any) => void;
  export type OnRemoteStreamListener = (session: ConferenceSession, userId: number, stream: MediaStream) => void;
  export type OnRemoteTracksUpdatedListener = (
    session: ConferenceSession,
    userId: number,
    track: MediaStreamTrack,
    eventType: any
  ) => void;
  export type OnRemoteConnectionStateChangedListener = (
    session: ConferenceSession,
    userId: number,
    iceState: any
  ) => void;
  export type OnDataChannelOpenedListener = (session: ConferenceSession, label: any) => void;
  export type OnDataChannelMessageListener = (session: ConferenceSession, label: any, data: any) => void;
  export type OnSessionConnectionStateChangedListener = (session: ConferenceSession, iceState: any) => void;
  export type OnErrorListener = (session: ConferenceSession, error: any) => void;
  export type Listeners =
    | OnParticipantJoinedListener
    | OnParticipantLeftListener
    | OnSlowLinkListener
    | OnRemoteStreamListener
    | OnRemoteTracksUpdatedListener
    | OnRemoteConnectionStateChangedListener
    | OnDataChannelOpenedListener
    | OnDataChannelMessageListener
    | OnSessionConnectionStateChangedListener
    | OnErrorListener;
}
