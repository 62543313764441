import { mediaDevices } from '../platform';
import config from '../config';
import ProxyService from '../Proxy';
import Utils from '../Utils';
import WebRTCSession from './WebRTCSession';
import WebRTCSignalingProcessor from './WebRTCSignalingProcessor';
import WebRTCSignalingProvider from './WebRTCSignalingProvider';
import WebRTCHelpers from './WebRTCHelpers';
import { XMPPClient } from '../messaging/xmpp';
import { CallsEvent, CallType, PeerConnectionState, SessionConnectionState, SessionState } from '../types/calls';
import { Calls } from '../types';
import { ProxyMethod } from '../types/proxy';

export default class WebRTCClient {
  public connection: XMPPClient;
  public proxy: ProxyService;
  public signalingProcessor: WebRTCSignalingProcessor;
  public signalingProvider: WebRTCSignalingProvider;
  public SessionConnectionState: typeof SessionConnectionState = SessionConnectionState;
  public PeerConnectionState: typeof PeerConnectionState = PeerConnectionState;
  public CallType: typeof CallType = CallType;
  public sessions: { [key: string]: WebRTCSession } = {};
  public onCallListener!: Calls.OnCallListener;
  public onAcceptCallListener!: Calls.OnAcceptCallListener;
  public onRejectCallListener!: Calls.OnRejectCallListener;
  public onStopCallListener!: Calls.OnStopCallListener;
  public onInvalidEventsListener!: Calls.OnInvalidEventsListener;
  public onUserNotAnswerListener!: Calls.OnUserNotAnswerListener;
  public onRemoteStreamListener!: Calls.OnRemoteStreamListener;
  public onSessionConnectionStateChangedListener!: Calls.OnSessionConnectionStateChangedListener;
  public onSessionCloseListener!: Calls.OnSessionCloseListener;
  public onCallStatsReport!: Calls.OnCallStatsReportListener;
  public onDevicesChangeListener: Calls.OnDevicesChangeListener = () => {};

  constructor(connection: XMPPClient, proxy: ProxyService) {
    this.connection = connection;
    this.proxy = proxy;

    this.signalingProcessor = new WebRTCSignalingProcessor(this);
    this.signalingProvider = new WebRTCSignalingProvider(connection);

    if (mediaDevices) {
      mediaDevices.ondevicechange = Utils.safeCallbackCall(this.onDevicesChangeListener);
    }
  }

  public async getMediaDevices(kind?: MediaDeviceKind): Promise<MediaDeviceInfo[]> {
    if (mediaDevices?.enumerateDevices) {
      const devices = await mediaDevices.enumerateDevices();
      return kind ? devices.filter((device) => device.kind === kind) : devices;
    } else {
      throw new Error("No 'enumerateDevices' API supported");
    }
  }

  public createNewSession(
    opponentsIDs: number[],
    callType: number,
    options?: { maxBandwidth?: number; bandwidth?: number }
  ): WebRTCSession {
    const callerJID = this.connection.jid?.toString() ?? '';
    const callerID = WebRTCHelpers.getUserIdFromJID(callerJID) ?? 0;
    const maxBandwidth = (options?.maxBandwidth || options?.bandwidth) ?? 0;

    if (!opponentsIDs) {
      throw new Error("Can't create a session without opponentsIDs");
    }

    return this.createAndStoreSession(null, callerID, opponentsIDs, callType, maxBandwidth);
  }

  private createAndStoreSession(
    sessionID: string | null,
    initiatorID: number,
    opponentsIDs: number[],
    callType: CallType,
    maxBandwidth: number = 0
  ): WebRTCSession {
    const currentUserJID = this.connection.jid?.toString() ?? '';
    const currentUserID = WebRTCHelpers.getUserIdFromJID(currentUserJID) ?? 0;
    const webRTCSession = new WebRTCSession({
      ID: sessionID,
      initiatorID,
      opponentsIDs,
      callType,
      signalingProvider: this.signalingProvider,
      currentUserID,
      maxBandwidth,
    });

    webRTCSession.onUserNotAnswerListener = this.onUserNotAnswerListener;
    webRTCSession.onRemoteStreamListener = this.onRemoteStreamListener;
    webRTCSession.onSessionConnectionStateChangedListener = this.onSessionConnectionStateChangedListener;
    webRTCSession.onSessionCloseListener = this.onSessionCloseListener;
    webRTCSession.onCallStatsReportListener = this.onCallStatsReport;

    if (webRTCSession?.ID) {
      this.sessions[webRTCSession.ID] = webRTCSession;
    }

    return webRTCSession;
  }

  public clearSession(sessionId: string): void {
    delete this.sessions[sessionId];
  }

  /// Reject call by http request
  public callRejectRequest(params: {
    sessionID: string;
    recipientId: number;
    platform: 'web' | 'android' | 'ios';
    userInfo?: any;
  }): Promise<any> {
    const ajaxParams = {
      type: ProxyMethod.POST,
      url: Utils.getUrl(config.urls.calls, 'reject'),
      data: params,
    };

    return this.proxy.ajax(ajaxParams);
  }

  /// DELEGATE (signaling)

  public onCallHandler(userID: number, sessionID: string, extension: Calls.ExtensionParams): void {
    const userInfo = extension.userInfo || {};
    const maxBandwidth = Number(userInfo.maxBandwidth ?? 0);

    let session = this.sessions[sessionID];

    WebRTCHelpers.trace(`onCall. userID: ${userID}, sessionID: ${sessionID}, extension: ${JSON.stringify(extension)}`);

    if (!session) {
      session = this.createAndStoreSession(
        sessionID,
        extension.callerID || 0,
        extension.opponentsIDs || [],
        extension.callType || CallType.VIDEO,
        maxBandwidth
      );

      Utils.safeCallbackCall(this.onCallListener)(session, userInfo);
    }

    session.processOnCall(userID, extension);
  }

  public onAcceptHandler(userID: number, sessionID: string, extension: Calls.ExtensionParams): void {
    const session = this.sessions[sessionID];
    const userInfo = extension.userInfo || {};

    WebRTCHelpers.trace(
      `onAccept. UserID: ${userID}, sessionID: ${sessionID}, extension: ${JSON.stringify(extension)}`
    );

    if (session && (session.state === SessionState.ACTIVE || session.state === SessionState.NEW)) {
      Utils.safeCallbackCall(this.onAcceptCallListener)(session, userID, userInfo);
      session.processOnAccept(userID, extension);
    } else {
      WebRTCHelpers.traceWarning(`Ignore 'onAccept', there is no information about session ${sessionID}`);
    }
  }

  public onRejectHandler(userID: number, sessionID: string, extension: Calls.ExtensionParams): void {
    const session = this.sessions[sessionID];

    WebRTCHelpers.trace(
      `onReject. UserID: ${userID}, sessionID: ${sessionID}, extension: ${JSON.stringify(extension)}`
    );

    if (session) {
      const userInfo = extension.userInfo || {};

      Utils.safeCallbackCall(this.onRejectCallListener)(session, userID, userInfo);
      session.processOnReject(userID);
    } else {
      WebRTCHelpers.traceWarning(`Ignore 'onReject', there is no information about session ${sessionID}`);
    }
  }

  public onStopHandler(userID: number, sessionID: string, extension: Calls.ExtensionParams) {
    WebRTCHelpers.trace(`onStop. UserID: ${userID}, sessionID: ${sessionID}, extension: ${JSON.stringify(extension)}`);

    const session = this.sessions[sessionID];
    const userInfo = extension.userInfo || {};

    if (session && (session.state === SessionState.ACTIVE || session.state === SessionState.NEW)) {
      session.processOnStop(userID);
      Utils.safeCallbackCall(this.onStopCallListener)(session, userID, userInfo);
    } else {
      Utils.safeCallbackCall(this.onInvalidEventsListener)(session, userID, userInfo);
      WebRTCHelpers.traceWarning(`Ignore 'onStop', there is no information about session ${sessionID} by some reason`);
    }
  }

  public onIceCandidatesHandler(userID: number, sessionID: string, extension: Calls.ExtensionParams) {
    const session = this.sessions[sessionID];

    WebRTCHelpers.trace(
      `onIceCandidates. UserID: ${userID}. SessionID: ${sessionID}. ICE candidates count: ${extension.iceCandidates?.length ?? 0}`
    );

    if (session) {
      if (session.state === SessionState.ACTIVE) {
        session.processOnIceCandidates(userID, extension);
      } else {
        WebRTCHelpers.traceWarning(`Ignore 'OnIceCandidates', the session ( ${sessionID} ) has invalid state`);
      }
    } else {
      WebRTCHelpers.traceWarning(`Ignore 'OnIceCandidates', there is no information about session ${sessionID}`);
    }
  }

  public onIceRestartHandler(userID: number, sessionID: string, extension: Calls.ExtensionParams) {
    const session = this.sessions[sessionID];

    WebRTCHelpers.trace(
      `onIceRestart. UserID: ${userID}. SessionID: ${sessionID}. Extension: ${JSON.stringify(extension)}`
    );

    if (session) {
      if (session.state === SessionState.ACTIVE) {
        session.processOnIceRestart(userID, extension);
      } else {
        WebRTCHelpers.traceWarning(`Ignore 'OnIceRestart', the session (${sessionID}) has invalid state`);
      }
    } else {
      WebRTCHelpers.traceWarning(`Ignore 'OnIceRestart', there is no information about session ${sessionID}`);
    }
  }

  public onIceRestartAcceptHandler(userID: number, sessionID: string, extension: Calls.ExtensionParams) {
    const session = this.sessions[sessionID];

    WebRTCHelpers.trace(
      `onIceRestartAccept. UserID: ${userID}. SessionID: ${sessionID}. Extension: ${JSON.stringify(extension)}`
    );

    if (session) {
      if (session.state === SessionState.ACTIVE) {
        session.processOnIceRestartAccept(userID, extension);
      } else {
        WebRTCHelpers.traceWarning(`Ignore 'onIceRestartAccept', the session (${sessionID}) has invalid state`);
      }
    } else {
      WebRTCHelpers.traceWarning(`Ignore 'onIceRestartAccept', there is no information about session ${sessionID}`);
    }
  }

  private getListenerByName(name: CallsEvent): string | null {
    switch (name) {
      case CallsEvent.CALL:
        return 'onCallListener';
      case CallsEvent.ACCEPT:
        return 'onAcceptCallListener';
      case CallsEvent.REJECT:
        return 'onRejectCallListener';
      case CallsEvent.HUNG_UP:
        return 'onStopCallListener';
      case CallsEvent.INVALID:
        return 'onInvalidEventsListener';
      case CallsEvent.NOT_ANSWER:
        return 'onUserNotAnswerListener';
      case CallsEvent.REMOTE_STREAM:
        return 'onRemoteStreamListener';
      case CallsEvent.CONNECTION_STATE:
        return 'onSessionConnectionStateChangedListener';
      case CallsEvent.CLOSE:
        return 'onSessionCloseListener';
      case CallsEvent.STATS_REPORT:
        return 'onCallStatsReport';
      case CallsEvent.DEVICES:
        return 'onDevicesChangeListener';
      default:
        return null;
    }
  }

  public addListener(name: CallsEvent, listener: Calls.Listeners): () => void {
    const listenerName = this.getListenerByName(name);
    if (listenerName) {
      this[listenerName] = listener;
    }
    return this.removeListener.bind(this, name);
  }

  public removeListener(name: CallsEvent): void {
    const listenerName = this.getListenerByName(name);
    if (listenerName) {
      this[listenerName] = name === CallsEvent.DEVICES ? () => {} : undefined;
    }
  }

  public removeAllListeners(): void {
    Object.keys(this).forEach((key) => {
      if (key.startsWith('on') && key.endsWith('Listener') && typeof this[key] === 'function') {
        this[key] = key === 'onDevicesChangeListener' ? () => {} : undefined;
      }
    });
  }
}
