import { ConnectyCubeConfig } from './config';
import { ConnectyCubeAuth } from './auth';

export enum ProxyMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
  HEAD = 'HEAD',
}
export enum ProxyType {
  JSON = 'json',
  TEXT = 'text',
}
export namespace ConnectyCubeProxy {
  export type Params = {
    url: string;
    type?: ProxyMethod;
    data?: any;
    contentType?: boolean;
    dataType?: ProxyType;
    useArrayQuery?: boolean;
    fileToCustomObject?: boolean;
    authKey?: string;
    abort_id?: string | number;
  };
  export type AbortControllersMap = {
    [key: string | number]: {
      controllers: AbortController[];
      doneRequestsCount?: number;
    };
  };
  export type SDKInstance = {
    config: ConnectyCubeConfig.Object;
    session: Partial<ConnectyCubeAuth.Session> | null;
  };
}
