import ProxyService from './Proxy';
import Utils from './Utils';
import config from './config';
import { Data, EmptyResponse, SearchCondition } from './types';
import { ProxyMethod, ProxyType } from './types/proxy';

export default class DataService {
  public proxy: ProxyService;
  readonly route = config.urls.data;

  constructor(proxy: ProxyService) {
    this.proxy = proxy;
  }

  public async create(className: string, params: Data.CreateParams = {}): Promise<Data.DataRecord> {
    const isMany = Utils.isArray(params);
    const multi = isMany ? 'multi' : undefined;
    const ajaxParams = {
      type: ProxyMethod.POST,
      url: Utils.getUrl(this.route, className, multi),
      data: isMany ? { record: this.createRecord(params) } : params,
    };

    return this.proxy.ajax(ajaxParams);
  }

  public async list(className: string, params: Data.ListParams = {}): Promise<Data.ListResponse> {
    const ext = typeof params === 'string' ? params : Utils.isArray(params) ? params.toString() : undefined;
    const ajaxParams = {
      url: Utils.getUrl(this.route, className, ext),
      data: ext ? undefined : params,
    };

    return this.proxy.ajax(ajaxParams);
  }

  public async readPermissions(className: string, id: string): Promise<Data.ReadPermissionsResponse> {
    const ajaxParams = {
      url: Utils.getUrl(this.route, className, id),
      data: { permissions: 1 },
    };

    return this.proxy.ajax(ajaxParams);
  }

  public async update(
    className: string,
    params: Data.UpdateOptions | Data.UpdateOptions[] = {}
  ): Promise<Data.UpdateResponse | Data.DataRecord> {
    const isMany = Utils.isArray(params);
    const isSearch = !isMany && params.search_criteria;
    const ext = isMany ? 'multi' : isSearch ? 'by_criteria' : params.id || params._id;
    const ajaxParams = {
      type: ProxyMethod.PUT,
      url: Utils.getUrl(this.route, className, ext),
      data: isMany ? { record: this.createRecord(params) } : params,
    };

    return this.proxy.ajax(ajaxParams);
  }

  public async delete(
    className: string,
    params: string | string[] | SearchCondition<any>
  ): Promise<Data.DeleteResponse | EmptyResponse> {
    const isCriteria = Utils.isObject(params);
    const isOne = typeof params === 'string' || (Utils.isArray(params) && params.length === 1);
    const ext = isCriteria ? 'by_criteria' : params.toString();
    const ajaxParams = {
      type: ProxyMethod.DELETE,
      url: Utils.getUrl(this.route, className, ext),
      data: isCriteria ? params : undefined,
      dataType: isOne ? ProxyType.TEXT : undefined,
    };

    return this.proxy.ajax(ajaxParams);
  }

  private createRecord(params: Data.CreateRecord[] = []): object {
    return params.reduce((items, item, index) => {
      const key = index + 1;
      const id = item.id || item._id;
      const record = id ? { ...item, id } : item;

      return { ...items, [key]: record };
    }, {});
  }
}
