import { Element, Node } from '@xmpp/xml';
import { ConnectyCubeDialogs } from './dialogs';
import { ConnectyCubeUsers } from './users';

export enum ChatType {
  CHAT = 'chat',
  GROUPCHAT = 'groupchat',
}
export enum ChatEvent {
  STATUS = 'status',
  ERROR = 'error',
  DISCONNECTED = 'disconnected',
  RECONNECTED = 'reconnected',
  MESSAGE = 'message',
  SYSTEM_MESSAGE = 'system-message',
  ERROR_MESSAGE = 'error-message',
  TYPING_MESSAGE = 'typing-message',
  UPDATE_MESSAGE = 'update-message',
  DELETE_MESSAGE = 'delete-message',
  REACTIONS_MESSAGE = 'reactions-message',
  DELIVERED_MESSAGE = 'delivered-message',
  READ_MESSAGE = 'read-message',
  SENT_MESSAGE = 'sent-message',
  USER_LAST_ACTIVITY = 'user-last-activity',
  ROSTER_SUBSCRIBE = 'roster-subscribe',
  ROSTER_CONFIRM = 'roster-confirm',
  ROSTER_REJECT = 'roster-reject',
  ROSTER_LIST = 'roster-list',
  JOIN = 'join',
  LEAVE = 'leave',
  KICK = 'kick',
}
export enum PrivacyListAction {
  ALLOW = 'allow',
  DENY = 'deny',
}
export namespace ConnectyCubeChat {
  export type XmlElement = Element;
  export type XmlNode = Node;
  export type StanzaCallback = (stanza?: Element) => void;
  export type StanzasCallbacks = { [stanzaId: string]: StanzaCallback };
  export type ConnectionParams = { userId: number; password: string };
  export type ConnectionStatus =
    | 'online' // indicates that xmpp is authenticated and addressable
    | 'offline' // indicates that xmpp disconnected and no automatic attempt to reconnect will happen (after calling xmpp.stop())
    | 'connecting' // socket is connecting
    | 'connect' // socket is connected
    | 'opening' // stream is opening
    | 'open' // stream is open
    | 'closing' // stream is closing
    | 'close' // stream is closed
    | 'disconnecting' // socket is disconnecting
    | 'disconnect'; // socket is disconnected
  export type MessageParams = {
    id?: string;
    type?: ChatType | string;
    body?: string | null;
    extension?: any;
    markable?: 1;
  };
  export type Message = {
    id: string;
    dialog_id: string | null;
    recipient_id: string | number | null;
    is_forwarded: boolean;
    type: ChatType | string;
    body: string | null;
    extension: any;
    delay: any;
    markable?: 1;
  };
  export type SystemMessageParams = {
    id?: string;
    body?: string | null;
    extension?: any;
  };
  export type SystemMessage = {
    id: string;
    body: string;
    userId: number;
    extension: any;
  };
  export type MessageStatusParams = {
    messageId: string;
    dialogId: string;
    userId: number;
  };
  export type EditMessageParams = {
    to: string | number;
    dialogId: string;
    body: string;
    originMessageId: string;
    extension?: any;
    last?: boolean;
  };
  export type DeleteMessageParams = {
    to: string;
    dialogId: string;
    messageId: string;
  };
  export type LastActivity = {
    userId: number;
    seconds: number;
  };
  export type SearchParams = {
    search_text?: string;
    start_date?: number | string | Date;
    end_date?: number | string | Date;
    chat_dialog_ids?: string | string[];
  };
  export type SearchResult = {
    results: {
      dialogs: ConnectyCubeDialogs.Dialog[];
      messages: any[];
      users: ConnectyCubeUsers.User[];
    };
    [key: string]: any;
  };
  export type UserJIDParams = {
    userId?: number;
    resource?: string;
    jid?: string;
    [key: string]: any;
  };
  // XMPP client events and listeners
  export type XMPPClientEvent = string | symbol;
  export type XMPPClientListener = (...args: any[]) => void;
  export type XMPPClientListeners = Map<XMPPClientEvent, XMPPClientListener>;
  // Chat listeners
  export type OnChatStatusListener = (status: ConnectionStatus) => void;
  export type OnChatConnectionErrorListener = () => void;
  export type OnChatDisconnectedListener = () => void;
  export type OnChatReconnectedListener = () => void;
  export type OnMessageListener = (userId: number, message: Message) => void;
  export type OnMessageSystemListener = (message: SystemMessage) => void;
  export type OnMessageErrorListener = (messageId: string, error: { code: number; info: string }) => void;
  export type OnMessageTypingListener = (typing: boolean, userId: number, dialogId: string) => void;
  export type OnMessageUpdateListener = (
    messageId: string,
    isLast: boolean,
    updatedBody: string,
    dialogId: string,
    userId: number,
    extraParams: object | null
  ) => void;
  export type OnMessageDeleteListener = (messageId: string, dialogId: string, userId: number) => void;
  export type OnMessageReactionsListener = (
    messageId: string,
    userId: number,
    dialogId: string,
    add: string,
    remove: string
  ) => void;
  export type OnMessageDeliveredListener = (messageId: string, dialogId: string, userId: number) => void;
  export type OnMessageReadListener = (messageId: string, dialogId: string, userId: number) => void;
  export type OnMessageSentListener = (lost: object | null, sent?: object) => void;
  export type OnLastUserActivityListener = (userId: LastActivity['userId'], seconds: LastActivity['seconds']) => void;
  export type OnRosterSubscribeListener = (userId: number) => void;
  export type OnRosterConfirmListener = (userId: number) => void;
  export type OnRosterRejectListener = (userId: number) => void;
  export type OnRosterListListener = (userId: number, type: string) => void;
  export type OnDialogJoinListener = (dialogId: string, userId: number) => void;
  export type OnDialogLeaveListener = (dialogId: string, userId: number) => void;
  export type OnDialogKickListener = (dialogId: string, initiatorId: number) => void;
  export type Listeners =
    | OnChatStatusListener
    | OnChatConnectionErrorListener
    | OnChatDisconnectedListener
    | OnChatReconnectedListener
    | OnMessageListener
    | OnMessageSystemListener
    | OnMessageErrorListener
    | OnMessageTypingListener
    | OnMessageUpdateListener
    | OnMessageDeleteListener
    | OnMessageReactionsListener
    | OnMessageDeliveredListener
    | OnMessageReadListener
    | OnMessageSentListener
    | OnLastUserActivityListener
    | OnRosterSubscribeListener
    | OnRosterConfirmListener
    | OnRosterRejectListener
    | OnRosterListListener
    | OnDialogJoinListener
    | OnDialogLeaveListener
    | OnDialogKickListener;
  // ContactList
  export type ContactListItem = { userId: number; name?: string | null };
  export type ContactList = {
    [userId: number]: {
      subscription: string;
      ask: string | null;
      name?: string | null;
    };
  };
  export type ContactListSubscriptionPresence = { jid: string; type: string };
  // PrivacyList
  export type PrivacyList = {
    name: string;
    items: {
      user_id: string | number;
      action: PrivacyListAction | string;
      mutualBlock?: boolean;
    }[];
  };
  export type PrivacyListNames = {
    default: string | null;
    active: string | null;
    names: string[];
  };
  export type PrivacyListItem = {
    value: string;
    action: string;
    order: number;
    isMutual: boolean;
  };
}
