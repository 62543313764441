import { XMPPClient } from './xmpp';
import ChatHelpers from './Helpers';
import ChatUtils from './ChatUtils';
import { Chat } from '../types';

export default class MultiUserChat {
  readonly xmlns = 'http://jabber.org/protocol/muc';
  public helpers: ChatHelpers;
  public xmppClient: XMPPClient;
  public stanzasCallbacks: Chat.StanzasCallbacks;
  public joinedRooms: { [jid: string]: boolean } = {};

  constructor(options: { helpers: ChatHelpers; xmppClient: XMPPClient; stanzasCallbacks: Chat.StanzasCallbacks }) {
    this.helpers = options.helpers;
    this.xmppClient = options.xmppClient;
    this.stanzasCallbacks = options.stanzasCallbacks;
  }

  public async join(dialogIdOrJid: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const id = ChatUtils.getUniqueId('join');
      const dialogJid = this.helpers.getDialogJid(dialogIdOrJid);
      const presenceStanza = ChatUtils.createPresenceStanza({
        id: id,
        from: this.helpers.userCurrentJid,
        to: this.helpers.getRoomJid(dialogJid),
      });

      presenceStanza.c('x', { xmlns: this.xmlns }).c('history', { maxstanzas: 0 });

      this.stanzasCallbacks[id] = (stanza: Chat.XmlElement) => {
        const x = ChatUtils.getElement(stanza, 'x');
        const status = ChatUtils.getElement(x, 'status');
        const code = ChatUtils.getAttr(status, 'code');

        if (status && code == '110') {
          this.joinedRooms[dialogJid] = true;
          resolve();
        } else {
          const isJoin = ChatUtils.getAttr(x, 'xmlns') === this.xmlns && id.endsWith(':join');
          const isError = ChatUtils.getAttr(stanza, 'type') === 'error';

          if (isJoin && isError) {
            const errorElement = ChatUtils.getElement(stanza, 'error');
            const errorResponse = {
              code: ChatUtils.getAttr(errorElement, 'code'),
              message: ChatUtils.getElementText(errorElement, 'text') || 'Unknown issue',
            };

            reject(errorResponse);
          }
        }
      };

      this.xmppClient.sendOnline(presenceStanza);
    });
  }

  public async leave(dialogIdOrJid: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const dialogJid = this.helpers.getDialogJid(dialogIdOrJid);
      const presenceStanza = ChatUtils.createPresenceStanza({
        type: 'unavailable',
        from: this.helpers.userCurrentJid,
        to: this.helpers.getRoomJid(dialogJid),
      });

      delete this.joinedRooms[dialogJid];

      this.stanzasCallbacks['muc:leave'] = (_stanza: Chat.XmlElement) => {
        resolve();
      };

      this.xmppClient.sendOnline(presenceStanza);
    });
  }

  public async listOnlineUsers(dialogIdOrJid): Promise<(string | number)[]> {
    return new Promise((resolve, _reject) => {
      const id = ChatUtils.getUniqueId('muc_disco_items');
      const iqStanza = ChatUtils.createIqStanza({
        type: 'get',
        to: this.helpers.getDialogJid(dialogIdOrJid),
        from: this.helpers.userCurrentJid,
        id,
      });

      iqStanza.c('query', { xmlns: 'http://jabber.org/protocol/disco#items' });

      this.stanzasCallbacks[id] = (stanza: Chat.XmlElement) => {
        const stanzaId = ChatUtils.getAttr(stanza, 'id');

        if (this.stanzasCallbacks[stanzaId]) {
          const query = ChatUtils.getAttr(stanza, 'query');
          const users = ChatUtils.getChildElements(query).map((item: Chat.XmlElement): string | number => {
            const roomJid = ChatUtils.getAttr(item, 'jid');
            return this.helpers.getUserIdFromRoomJid(roomJid);
          });

          resolve(users);
        }
      };

      this.xmppClient.sendOnline(iqStanza);
    });
  }
}
