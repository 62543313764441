import config from '../config';
import { XMPPClient, xmppClient } from './xmpp';
import Utils from '../Utils';
import ProxyService from '../Proxy';
import DialogsService from './Dialogs';
import MessagesService from './Messages';
import ChatHelpers from './Helpers';
import ChatUtils from './ChatUtils';
import StreamManagement from './StreamManagement';
import ContactList from './ContactList';
import WebRTCSignalingProcessor from '../videocalling/WebRTCSignalingProcessor';
import PrivacyList from './PrivacyList';
import MultiUserChat from './MultiUserChat';
import { Chat } from '../types';
import { ChatEvent } from '../types/chat';
import { ProxyMethod } from '../types/proxy';

export default class ChatService {
  public proxy: ProxyService;
  public dialog: DialogsService;
  public message: MessagesService;
  public xmppClient: XMPPClient;
  public helpers: ChatHelpers;
  public contactlist: ContactList;
  public privacylist: PrivacyList;
  public muc: MultiUserChat;
  public streamManagement!: StreamManagement;
  public webrtcSignalingProcessor!: WebRTCSignalingProcessor;

  public isConnected: boolean = false;
  private isConnecting: boolean = false;
  private isLogout: boolean = false;
  private isReconnect: boolean = false;

  public stanzasCallbacks: Chat.StanzasCallbacks = {};
  private xmppClientListeners: Chat.XMPPClientListeners = new Map<Chat.XMPPClientEvent, Chat.XMPPClientListener>();
  private connectPromise: Promise<boolean> | null = null;
  private earlyIncomingMessagesQueue: Chat.XmlElement[] = [];
  private pingTimer: NodeJS.Timeout | null = null;

  public onChatStatusListener!: Chat.OnChatStatusListener;
  public onConnectionErrorListener!: Chat.OnChatConnectionErrorListener;
  public onDisconnectedListener!: Chat.OnChatDisconnectedListener;
  public onReconnectListener!: Chat.OnChatReconnectedListener;
  public onMessageListener!: Chat.OnMessageListener;
  public onSystemMessageListener!: Chat.OnMessageSystemListener;
  public onMessageErrorListener!: Chat.OnMessageErrorListener;
  public onMessageTypingListener!: Chat.OnMessageTypingListener;
  public onMessageUpdateListener!: Chat.OnMessageUpdateListener;
  public onMessageDeleteListener!: Chat.OnMessageDeleteListener;
  public onMessageReactionsListener!: Chat.OnMessageReactionsListener;
  public onSentMessageCallback!: Chat.OnMessageSentListener;
  public onDeliveredStatusListener!: Chat.OnMessageDeliveredListener;
  public onReadStatusListener!: Chat.OnMessageReadListener;
  public onLastUserActivityListener!: Chat.OnLastUserActivityListener;
  public onSubscribeListener!: Chat.OnRosterSubscribeListener;
  public onConfirmSubscribeListener!: Chat.OnRosterConfirmListener;
  public onRejectSubscribeListener!: Chat.OnRosterRejectListener;
  public onContactListListener!: Chat.OnRosterListListener;
  public onJoinOccupant!: Chat.OnDialogJoinListener;
  public onLeaveOccupant!: Chat.OnDialogLeaveListener;
  public onKickOccupant!: Chat.OnDialogKickListener;

  constructor(proxy: ProxyService) {
    this.proxy = proxy;
    this.dialog = new DialogsService(proxy);
    this.message = new MessagesService(proxy);
    this.helpers = new ChatHelpers();

    this.xmppClient = xmppClient({
      service: config.chatProtocol.websocket,
      credentials: (auth, _mechanism) =>
        auth({
          username: this.xmppClient.options.username || '',
          password: this.xmppClient.options.password || '',
        }),
    });

    if (config.chat.reconnect.enable) {
      this.xmppClient.reconnect.delay = config.chat.reconnect.timeInterval * 1000;
    }

    const options = {
      xmppClient: this.xmppClient,
      helpers: this.helpers,
      stanzasCallbacks: this.stanzasCallbacks,
    };

    this.contactlist = new ContactList(options);
    this.privacylist = new PrivacyList(options);
    this.muc = new MultiUserChat(options);

    if (this.isStreamManagementSupported) {
      this.streamManagement = new StreamManagement();
    }
  }

  get connectionStatus() {
    return this.xmppClient.status;
  }

  public async connect(params: Chat.ConnectionParams): Promise<boolean> {
    this.connectPromise = new Promise((resolve, reject) => {
      Utils.DLog('[Chat]', 'Connect with parameter:', params);

      if (this.isConnecting) {
        Utils.DLog('[Chat]', 'Warning! Already in CONNECTING state');
        resolve(false);
        return;
      }

      if (this.isConnected) {
        Utils.DLog('[Chat]', 'Warning! Chat is already connected!');
        resolve(false);
        return;
      }

      this.isConnecting = true;
      this.isLogout = false;

      this.removeAllXMPPClientListeners();

      this.addXMPPClientListener('connect', () => {
        Utils.DLog('[Chat]', this.isReconnect ? 'RECONNECTING' : 'CONNECTING');
      });

      this.addXMPPClientListener('online', () => {
        Utils.DLog('[Chat]', 'ONLINE');
        this.startPingTimer();
        this.postConnectActions();
        resolve(true);
        this.connectPromise = null;
      });

      this.addXMPPClientListener('offline', () => {
        Utils.DLog('[Chat]', 'OFFLINE');
      });

      this.addXMPPClientListener('disconnect', () => {
        Utils.DLog('[Chat]', 'DISCONNECTED');

        Utils.safeCallbackCall(this.onDisconnectedListener)();

        if (config.chat.reconnect.enable) {
          this.isConnected = false;
          this.isConnecting = false;
        } else {
          this.disconnect();
        }

        this.stopPingTimer();
      });

      this.addXMPPClientListener('status', (status: Chat.ConnectionStatus, value: any) => {
        Utils.DLog('[Chat]', `status - ${status}`, typeof value === 'object' ? JSON.stringify(value) : '');
        Utils.safeCallbackCall(this.onChatStatusListener)(status);
      });

      this.addXMPPClientListener('stanza', (stanza: Chat.XmlElement) => {
        // it can be a case,
        // when message came after xmpp auth but before resource bindging,
        // and it can cause some crashes, e.g.
        // https://github.com/ConnectyCube/connectycube-js-sdk-releases/issues/28
        if (stanza.is('message') && !this.isConnected) {
          this.earlyIncomingMessagesQueue.push(stanza);
          Utils.DLog('[Chat]', "on 'stanza': enqueue incoming stanza (isConnected=false)");
          return;
        }

        // after 'input' and 'element' (only if stanza, not nonza)
        if (stanza.is('presence')) {
          this.onPresence(stanza);
        } else if (stanza.is('iq')) {
          this.onIQ(stanza);
        } else if (stanza.is('message')) {
          if (stanza.attrs.type === 'headline') {
            this.onSystemMessage(stanza);
          } else if (stanza.attrs.type === 'error') {
            this.onMessageError(stanza);
          } else {
            this.onMessage(stanza);
          }
        }
      });

      this.addXMPPClientListener('error', (err) => {
        Utils.DLog('[Chat]', 'ERROR:', err, {
          isReconnect: this.isReconnect,
          connectPromise: !!this.connectPromise,
        });

        if (this.connectPromise) {
          if (!this.isReconnect) {
            if (err.name == 'SASLError') {
              err = err.condition;
            }
            reject(err);
            this.connectPromise = null;
          }
        } else {
          Utils.safeCallbackCall(this.onConnectionErrorListener)();
        }
      });

      this.addXMPPClientListener('output', (str) => {
        Utils.callTrafficUsageCallback('xmppDataWrite', { body: str });
        Utils.DLog('[Chat]', 'SENT:', str);
      });

      this.addXMPPClientListener('input', (str) => {
        Utils.callTrafficUsageCallback('xmppDataRead', { body: str });
        Utils.DLog('[Chat]', 'RECV:', str);
      });

      // save user connection data so they will be used when authenticate (above)
      this.xmppClient.options.username = ChatUtils.buildUserJidLocalPart(params.userId);
      this.xmppClient.options.password = params.password;
      // start connect
      this.xmppClient.start().catch((err) => {
        console.error('[Chat] xmppClient.start error', err);
        if (this.connectPromise) {
          reject(err);
          this.connectPromise = null;
        } else {
          Utils.safeCallbackCall(this.onConnectionErrorListener)();
        }
      });
    });

    return this.connectPromise;
  }

  /**
   * @deprecated Use `Chat ContactList` will be removed in the next major release.
   */
  public async getContacts(): Promise<Chat.ContactList> {
    console.warn(
      '[Deprecated][Chat][ContactList][[XMPP][Roster]]',
      'ConnectyCube.chat.getContacts() and the ConnectyCube.chat.contactlist class will be removed in the next major release.'
    );
    try {
      const contacts = await this.contactlist.get();
      this.contactlist.contacts = contacts;
      return contacts;
    } catch (error) {
      throw error;
    }
  }

  public async ping(): Promise<void> {
    return new Promise((resolve, reject) => {
      const id = ChatUtils.getUniqueId('ping');
      const iqStanza = ChatUtils.createIqStanza({ id, type: 'get', to: config.endpoints.chat });

      iqStanza.c('ping', { xmlns: 'urn:xmpp:ping' });

      this.stanzasCallbacks[id] = (stanza: Chat.XmlElement) => {
        const error = ChatUtils.getElement(stanza, 'error');
        if (error) {
          reject(ChatUtils.buildErrorFromXMPPErrorStanza(error));
        } else {
          resolve();
        }
      };

      this.xmppClient.sendOnline(iqStanza);
    });
  }

  public async pingWithTimeout(timeout: number = 5000): Promise<unknown> {
    return Promise.race([
      this.ping(),
      new Promise((_, reject) => setTimeout(() => reject(new Error('Chat ping() timed out')), timeout)),
    ]);
  }

  private startPingTimer(): void {
    this.stopPingTimer();

    if (config.chat.ping.enable) {
      // Min time interval between pings is 30 seconds.
      const validTime = config.chat.ping.timeInterval < 30 ? 30 : config.chat.ping.timeInterval;

      this.pingTimer = setInterval(() => {
        this.ping();
      }, validTime * 1000);
    }
  }

  private stopPingTimer(): void {
    if (this.pingTimer) {
      clearInterval(this.pingTimer);
      this.pingTimer = null;
    }
  }

  public send(jidOrUserId: string | number, message: Chat.MessageParams = {}): string {
    const messageId = message.id ?? Utils.getBsonObjectId();
    const messageStanza = ChatUtils.createMessageStanza({
      from: this.helpers.userCurrentJid,
      to: this.helpers.jidOrUserId(jidOrUserId),
      type: message.type ?? this.helpers.typeChat(jidOrUserId),
      id: messageId,
    });

    if (!message.id) {
      message.id = messageId;
    }

    if (message.body) {
      messageStanza.c('body').t(message.body).up();
    }

    if (message.markable) {
      messageStanza.c('markable', { xmlns: 'urn:xmpp:chat-markers:0' }).up();
    }

    if (message.extension) {
      messageStanza.c('extraParams', { xmlns: 'jabber:client' });
      ChatUtils.assignExtraParamsToXml(messageStanza, message.extension);
    }

    if (this.isStreamManagementSupported) {
      this.xmppClient.sendOnline(messageStanza, message);
    } else {
      this.xmppClient.sendOnline(messageStanza);
    }

    return messageId;
  }

  public sendSystemMessage(jidOrUserId: string | number, message: Chat.SystemMessageParams): string {
    const messageId = message.id ?? Utils.getBsonObjectId();
    const messageStanza = ChatUtils.createMessageStanza({
      type: 'headline',
      id: messageId,
      to: this.helpers.jidOrUserId(jidOrUserId),
    });

    if (message.body) {
      messageStanza.c('body').t(message.body).up();
    }

    if (message.extension) {
      messageStanza.c('extraParams', { xmlns: 'jabber:client' }).c('moduleIdentifier').t('SystemNotifications').up();
      ChatUtils.assignExtraParamsToXml(messageStanza, message.extension);
    }

    this.xmppClient.sendOnline(messageStanza);

    return messageId;
  }

  public sendIsTypingStatus(jidOrUserId: string | number): void {
    const messageStanza = ChatUtils.createMessageStanza({
      from: this.helpers.userCurrentJid,
      to: this.helpers.jidOrUserId(jidOrUserId),
      type: this.helpers.typeChat(jidOrUserId),
    });

    messageStanza.c('composing', { xmlns: 'http://jabber.org/protocol/chatstates' });

    this.xmppClient.sendOnline(messageStanza);
  }

  public sendIsStopTypingStatus(jidOrUserId: string | number): void {
    const messageStanza = ChatUtils.createMessageStanza({
      from: this.helpers.userCurrentJid,
      to: this.helpers.jidOrUserId(jidOrUserId),
      type: this.helpers.typeChat(jidOrUserId),
    });

    messageStanza.c('paused', { xmlns: 'http://jabber.org/protocol/chatstates' });

    this.xmppClient.sendOnline(messageStanza);
  }

  public sendDeliveredStatus(params: Chat.MessageStatusParams): void {
    const messageStanza = ChatUtils.createMessageStanza({
      type: 'chat',
      from: this.helpers.userCurrentJid,
      id: Utils.getBsonObjectId(),
      to: this.helpers.jidOrUserId(params.userId),
    });

    messageStanza.c('received', { xmlns: 'urn:xmpp:chat-markers:0', id: params.messageId }).up();
    messageStanza.c('extraParams', { xmlns: 'jabber:client' }).c('dialog_id').t(params.dialogId);

    this.xmppClient.sendOnline(messageStanza);
  }

  public sendReadStatus(params: Chat.MessageStatusParams): void {
    const messageStanza = ChatUtils.createMessageStanza({
      type: 'chat',
      from: this.helpers.userCurrentJid,
      to: this.helpers.jidOrUserId(params.userId),
      id: Utils.getBsonObjectId(),
    });

    messageStanza.c('displayed', { xmlns: 'urn:xmpp:chat-markers:0', id: params.messageId }).up();
    messageStanza.c('extraParams', { xmlns: 'jabber:client' }).c('dialog_id').t(params.dialogId);

    this.xmppClient.sendOnline(messageStanza);
  }

  public editMessage(params: Chat.EditMessageParams): void {
    const messageStanza = ChatUtils.createMessageStanza({
      from: this.helpers.userCurrentJid,
      to: this.helpers.jidOrUserId(params.to),
      type: this.helpers.typeChat(params.to),
      id: Utils.getBsonObjectId(),
    });

    messageStanza.c('body').t(params.body).up();
    messageStanza
      .c('replace', {
        xmlns: 'urn:xmpp:message-correct:0',
        id: params.originMessageId,
        last: params.last ? 'true' : 'false',
      })
      .up();
    messageStanza.c('extraParams', { xmlns: 'jabber:client' }).c('dialog_id').t(params.dialogId);

    if (params.extension) {
      ChatUtils.assignExtraParamsToXml(messageStanza, params.extension);
    }

    this.xmppClient.sendOnline(messageStanza);
  }

  public deleteMessage(params: Chat.DeleteMessageParams): void {
    const messageStanza = ChatUtils.createMessageStanza({
      from: this.helpers.userCurrentJid,
      to: this.helpers.jidOrUserId(params.to),
      type: this.helpers.typeChat(params.to),
      id: Utils.getBsonObjectId(),
    });

    messageStanza.c('remove', { xmlns: 'urn:xmpp:message-delete:0', id: params.messageId }).up();
    messageStanza.c('extraParams', { xmlns: 'jabber:client' }).c('dialog_id').t(params.dialogId);

    this.xmppClient.sendOnline(messageStanza);
  }

  public getLastUserActivity(jidOrUserId: string | number): Promise<Chat.LastActivity> {
    return new Promise((resolve, reject) => {
      const id = ChatUtils.getUniqueId('lastActivity');
      const iqStanza = ChatUtils.createIqStanza({
        type: 'get',
        from: this.helpers.userCurrentJid,
        to: this.helpers.jidOrUserId(jidOrUserId),
        id: id,
      });

      iqStanza.c('query', { xmlns: 'jabber:iq:last' });

      this.stanzasCallbacks[id] = (stanza: Chat.XmlElement) => {
        if (ChatUtils.getElement(stanza, 'error')) {
          reject(ChatUtils.buildErrorFromXMPPErrorStanza(stanza));
        } else {
          resolve(this.onLastActivityStanza(stanza));
        }
      };

      this.xmppClient.sendOnline(iqStanza);
    });
  }

  private onLastActivityStanza(stanza: Chat.XmlElement): Chat.LastActivity {
    const from = ChatUtils.getAttr(stanza, 'from');
    const userId = this.helpers.getUserIdFromJID(from);
    const query = ChatUtils.getElement(stanza, 'query');
    const seconds = query ? parseInt(ChatUtils.getAttr(query, 'seconds')) : 0;

    Utils.safeCallbackCall(this.onLastUserActivityListener)(userId, seconds);

    return { userId, seconds };
  }

  public markActive(): void {
    const iqStanza = ChatUtils.createIqStanza({
      id: this.helpers.getUniqueId('markActive'),
      type: 'set',
    });

    iqStanza.c('mobile', {
      xmlns: 'http://tigase.org/protocol/mobile#v2',
      enable: 'false',
    });

    this.xmppClient.sendOnline(iqStanza);
  }

  public markInactive(): void {
    const iqStanza = ChatUtils.createIqStanza({
      id: this.helpers.getUniqueId('markActive'),
      type: 'set',
    });

    iqStanza.c('mobile', {
      xmlns: 'http://tigase.org/protocol/mobile#v2',
      enable: 'true',
    });

    this.xmppClient.sendOnline(iqStanza);
  }

  public async disconnect(): Promise<boolean> {
    Utils.DLog('[Chat]', 'disconnect');

    if (this.isLogout) {
      Utils.DLog('[Chat]', 'Warning! Chat is already disconnected!');
      return true;
    }

    this.muc.joinedRooms = {};
    this.isConnected = false;
    this.isConnecting = false;
    this.isLogout = true;
    this.isReconnect = false;
    this.helpers.userCurrentJid = '';

    if (this.isStreamManagementSupported) {
      this.streamManagement.removeElementHandler();
    }

    try {
      await this.xmppClient.stop();
      return true;
    } catch {
      return false;
    }
  }

  public async search(params: Chat.SearchParams): Promise<Chat.SearchResult> {
    const query = Object.assign({}, params);

    if (query.start_date) {
      query.start_date = new Date(query.start_date).toISOString();
    }
    if (query.end_date) {
      query.end_date = new Date(query.end_date).toISOString();
    }
    if (Utils.isArray(query.chat_dialog_ids)) {
      query.chat_dialog_ids = (query.chat_dialog_ids as string[]).join(',');
    }

    const ajaxParams = {
      type: ProxyMethod.GET,
      url: Utils.getUrl(`${config.urls.chat}/search`),
      data: query,
    };

    return this.proxy.ajax(ajaxParams);
  }

  private onMessage(rawStanza: Chat.XmlElement): void {
    const forwardedStanza = ChatUtils.getElementTreePath(rawStanza, ['sent', 'forwarded', 'message']);
    const stanza = forwardedStanza || rawStanza;
    const from = ChatUtils.getAttr(stanza, 'from');
    const type = ChatUtils.getAttr(stanza, 'type');
    const invite = ChatUtils.getElement(stanza, 'invite');
    const resource = this.xmppClient.jid?.getResource() || '';
    const isChat = type === 'chat';

    // ignore private message from the same resource or invite messages from MUC
    if ((isChat && from.includes(resource)) || invite) {
      return;
    }

    const messageId = ChatUtils.getAttr(stanza, 'id');
    const markable = ChatUtils.getElement(stanza, 'markable');
    const delivered = ChatUtils.getElement(stanza, 'received');
    const read = ChatUtils.getElement(stanza, 'displayed');
    const replaceSubElement = ChatUtils.getElement(stanza, 'replace');
    const reactionsSubElement = ChatUtils.getElement(stanza, 'reactions');
    const removeSubElement = ChatUtils.getElement(stanza, 'remove');
    const composing = Boolean(ChatUtils.getElement(stanza, 'composing'));
    const paused = ChatUtils.getElement(stanza, 'paused');
    const delay = ChatUtils.getElement(stanza, 'delay');
    const extraParams = ChatUtils.getElement(stanza, 'extraParams');
    const body = ChatUtils.getElementText(stanza, 'body');
    const isForwarded = Boolean(forwardedStanza);
    const isGroup = type === 'groupchat';
    const recipient = isChat ? ChatUtils.getAttr(stanza, 'to') : null;
    const recipientId = recipient ? this.helpers.getUserIdFromJID(recipient) : null;
    const ext = extraParams ? ChatUtils.parseExtraParams(extraParams) : null;
    const extension = ext ? ext.extension : null;
    const dialogId = isGroup ? this.helpers.getDialogIdFromJID(from) : (ext?.dialogId ?? null);
    const userId = isGroup ? this.helpers.getIdFromResource(from) : this.helpers.getUserIdFromJID(from);
    const currentUserJid = this.xmppClient.jid?.toString();
    const currentUserId = currentUserJid ? this.helpers.getUserIdFromJID(currentUserJid) : 0;

    // typing statuses
    if (composing || paused) {
      if (isChat || isGroup || !delay) {
        Utils.safeCallbackCall(this.onMessageTypingListener)(composing, userId, dialogId);
      }

      return;
    }

    // edit message
    if (replaceSubElement) {
      Utils.safeCallbackCall(this.onMessageUpdateListener)(
        ChatUtils.getAttr(replaceSubElement, 'id'),
        ChatUtils.getAttr(replaceSubElement, 'last') === 'true',
        body,
        dialogId,
        userId,
        extension
      );

      return;
    }

    // reactions
    if (reactionsSubElement) {
      if (isForwarded && isGroup) {
        return;
      }

      const messageId = ChatUtils.getAttr(reactionsSubElement, 'message_id');
      const userId = parseInt(ChatUtils.getAttr(reactionsSubElement, 'user_id'));
      const { add, remove } = ChatUtils.parseReactions(reactionsSubElement);

      Utils.safeCallbackCall(this.onMessageReactionsListener)(messageId, userId, dialogId, add, remove);

      return;
    }

    // delete message
    if (removeSubElement) {
      const messageId = ChatUtils.getAttr(removeSubElement, 'id');

      Utils.safeCallbackCall(this.onMessageDeleteListener)(messageId, dialogId, userId);

      return;
    }

    // delivered / read statuses
    if (delivered || read) {
      if (delivered && isChat) {
        const id = ChatUtils.getAttr(delivered, 'id');
        Utils.safeCallbackCall(this.onDeliveredStatusListener)(id, dialogId, userId);
      }
      if (read && isChat) {
        const id = ChatUtils.getAttr(read, 'id');
        Utils.safeCallbackCall(this.onReadStatusListener)(id, dialogId, userId);
      }

      return;
    }

    // auto-send 'received' status (ignore messages from yourself)
    if (markable && dialogId && userId && userId !== currentUserId) {
      this.sendDeliveredStatus({ messageId, dialogId, userId });
    }

    const message: Chat.Message = {
      id: messageId,
      dialog_id: dialogId,
      recipient_id: recipientId,
      is_forwarded: isForwarded,
      type,
      body,
      extension,
      delay,
    };

    if (markable) {
      message.markable = 1;
    }

    if (isChat || isGroup) {
      Utils.safeCallbackCall(this.onMessageListener)(userId, message);
    }
  }

  private onPresence(stanza: Chat.XmlElement): void {
    const from = ChatUtils.getAttr(stanza, 'from');
    const id = ChatUtils.getAttr(stanza, 'id');
    const type = ChatUtils.getAttr(stanza, 'type');
    const currentUserJid = this.xmppClient.jid?.toString();
    const currentUserId = this.helpers.getUserIdFromJID(currentUserJid);
    const x = ChatUtils.getElement(stanza, 'x');
    const xmlns = x ? ChatUtils.getAttr(x, 'xmlns') : null;
    const status = x ? ChatUtils.getElement(x, 'xmlns') : null;
    // const statusCode = status ? ChatUtils.getElement(status, 'code') : null;
    const statusCode = status ? ChatUtils.getElementText(status, 'code') : null;

    // MUC presences
    if (xmlns && xmlns.startsWith('http://jabber.org/protocol/muc')) {
      // Error
      if (type === 'error') {
        // JOIN to dialog error
        if (id.endsWith(':join')) {
          Utils.safeCallbackCall(this.stanzasCallbacks[id])(stanza);
        }
        return;
      }

      const dialogId = this.helpers.getDialogIdFromJID(from);
      const mucUserId = this.helpers.getUserIdFromRoomJid(from);

      // self presence
      if (status) {
        // KICK from dialog event
        if (statusCode === '301') {
          const item = ChatUtils.getElement(stanza, 'item');
          const actorElement = item ? ChatUtils.getElement(item, 'actor') : null;
          const initiatorUserJid = actorElement ? ChatUtils.getAttr(actorElement, 'jid') : null;
          const initiatorId = this.helpers.getUserIdFromJID(initiatorUserJid);
          const roomJid = this.helpers.getRoomJidFromRoomFullJid(from);

          Utils.safeCallbackCall(this.onKickOccupant)(dialogId, initiatorId);

          if (roomJid) {
            delete this.muc.joinedRooms[roomJid];
          }

          return;
        } else {
          if (type === 'unavailable') {
            // LEAVE response
            if (status && statusCode === '110') {
              Utils.safeCallbackCall(this.stanzasCallbacks['muc:leave'])(null);
            }
            return;
          }
          // JOIN response
          if (id.endsWith(':join') && status && statusCode === '110') {
            this.stanzasCallbacks[id]?.(stanza);
            return;
          }
        }
        // Occupants JOIN/LEAVE events
      } else if (mucUserId != currentUserId) {
        const listenerName = type === 'unavailable' ? 'onLeaveOccupant' : 'onJoinOccupant';

        Utils.safeCallbackCall(this[listenerName])(dialogId, mucUserId);

        return;
      }
    }

    // ROSTER presences
    const userId = this.helpers.getUserIdFromJID(from) ?? 0;
    const contact = this.contactlist.contacts[userId] ?? { ask: null, subscription: null };

    switch (type) {
      case 'subscribe':
        if (contact?.subscription === 'to') {
          contact.ask = null;
          contact.subscription = 'both';

          this.contactlist.sendSubscriptionPresence({
            jid: from,
            type: 'subscribed',
          });
        } else {
          Utils.safeCallbackCall(this.onSubscribeListener)(userId);
        }
        break;
      case 'subscribed':
        if (contact?.subscription === 'from') {
          contact.ask = null;
          contact.subscription = 'both';
        } else {
          contact.ask = null;
          contact.subscription = 'to';

          Utils.safeCallbackCall(this.onConfirmSubscribeListener)(userId);
        }
        break;
      case 'unsubscribed':
        contact.ask = null;
        contact.subscription = 'none';

        Utils.safeCallbackCall(this.onRejectSubscribeListener)(userId);

        break;
      case 'unsubscribe':
        contact.ask = null;
        contact.subscription = 'to';
        break;
      case 'unavailable':
        if (contact?.subscription !== 'none') {
          Utils.safeCallbackCall(this.onContactListListener)(userId, 'unavailable');
        }
        // send initial presence if one of client (instance) goes offline
        if (userId === currentUserId) {
          this.xmppClient.sendOnline(ChatUtils.createPresenceStanza());
        }
        break;
      case 'available':
      default:
        if (contact?.subscription !== 'none') {
          Utils.safeCallbackCall(this.onContactListListener)(userId, 'available');
        }

        break;
    }
  }

  private onIQ(stanza: Chat.XmlElement): void {
    const stanzaId = ChatUtils.getAttr(stanza, 'id');

    if (this.stanzasCallbacks[stanzaId]) {
      Utils.safeCallbackCall(this.stanzasCallbacks[stanzaId])(stanza);
      delete this.stanzasCallbacks[stanzaId];
    } else {
      const from = ChatUtils.getAttr(stanza, 'from');
      const query = ChatUtils.getElement(stanza, 'query');
      if (from && query) {
        this.onLastActivityStanza(stanza);
      }
    }
  }

  private onSystemMessage(rawStanza: Chat.XmlElement): void {
    const forwardedStanza = ChatUtils.getElementTreePath(rawStanza, ['sent', 'forwarded', 'message']);
    const stanza = forwardedStanza || rawStanza;
    const from = ChatUtils.getAttr(stanza, 'from');
    const messageId = ChatUtils.getAttr(stanza, 'id');
    const extraParams = ChatUtils.getElement(stanza, 'extraParams');
    const userId = this.helpers.getUserIdFromJID(from);
    const delay = ChatUtils.getElement(stanza, 'delay');
    const moduleIdentifier = extraParams ? ChatUtils.getElementText(extraParams, 'moduleIdentifier') : null;
    const body = ChatUtils.getElementText(stanza, 'body');
    const ext = extraParams ? ChatUtils.parseExtraParams(extraParams) : null;
    const extension = ext ? ext.extension : null;

    switch (moduleIdentifier) {
      case 'SystemNotifications':
        Utils.safeCallbackCall(this.onSystemMessageListener)({ id: messageId, userId, body, extension });
        break;
      case 'WebRTCVideoChat':
        if (this.webrtcSignalingProcessor && !delay) {
          Utils.safeCallbackCall(this.webrtcSignalingProcessor.onMessage)(userId, extraParams);
        }
        break;
      default:
        break;
    }
  }

  private onMessageError(stanza: Chat.XmlElement): void {
    // <error code="503" type="cancel">
    //   <service-unavailable xmlns="urn:ietf:params:xml:ns:xmpp-stanzas"/>
    //   <text xmlns="urn:ietf:params:xml:ns:xmpp-stanzas" xml:lang="en">Service not available.</text>
    // </error>
    const messageId = ChatUtils.getAttr(stanza, 'id');
    const error = ChatUtils.buildErrorFromXMPPErrorStanza(stanza);

    Utils.safeCallbackCall(this.onMessageErrorListener)(messageId, error);
  }

  private postConnectActions(): void {
    Utils.DLog('[Chat]', this.isReconnect ? 'RECONNECTED' : 'CONNECTED');

    const presence = ChatUtils.createPresenceStanza();

    if (this.isStreamManagementSupported) {
      this.streamManagement.enable(this.xmppClient);
      this.streamManagement.sentMessageCallback = (messageLost, messageSent) => {
        Utils.safeCallbackCall(this.onSentMessageCallback)(messageSent ? null : messageLost, messageSent);
      };
    }

    this.helpers.userCurrentJid = this.xmppClient.jid?.toString() ?? null;
    this.isConnected = true;
    this.isConnecting = false;
    this.enableCarbons();
    this.xmppClient.sendOnline(presence); // initial presence

    if (this.isReconnect) {
      // reconnect
      Utils.safeCallbackCall(this.onReconnectListener)();
    } else {
      this.isReconnect = true;
    }

    if (this.earlyIncomingMessagesQueue.length > 0) {
      Utils.DLog('[Chat]', `Flush 'earlyIncomingMessagesQueue' (length=${this.earlyIncomingMessagesQueue.length})`);

      const stanzasCallback = this.xmppClientListeners.get('stanza');

      this.earlyIncomingMessagesQueue.forEach((stanza) => {
        stanzasCallback?.(stanza);
      });
      this.earlyIncomingMessagesQueue = [];
    }
  }

  private enableCarbons(): void {
    const iqStanza = ChatUtils.createIqStanza({
      type: 'set',
      from: this.helpers.userCurrentJid,
      id: ChatUtils.getUniqueId('enableCarbons'),
    });

    iqStanza.c('enable', { xmlns: 'urn:xmpp:carbons:2' });

    this.xmppClient.sendOnline(iqStanza);
  }

  private setSubscriptionToUserLastActivity(jidOrUserId: string | number, enable: boolean): void {
    const iqStanza = ChatUtils.createIqStanza({
      id: this.helpers.getUniqueId('statusStreaming'),
      type: 'set',
    });

    iqStanza.c('subscribe', {
      xmlns: 'https://connectycube.com/protocol/status_streaming',
      user_jid: this.helpers.jidOrUserId(jidOrUserId),
      enable,
    });

    this.xmppClient.sendOnline(iqStanza);
  }

  subscribeToUserLastActivityStatus(jidOrUserId: string | number) {
    this.setSubscriptionToUserLastActivity(jidOrUserId, true);
  }

  unsubscribeFromUserLastActivityStatus(jidOrUserId: string | number) {
    this.setSubscriptionToUserLastActivity(jidOrUserId, false);
  }

  private get isStreamManagementSupported(): boolean {
    const isEnabled = config.chat.streamManagement?.enable ? true : false;
    const isWebsocket = config.chatProtocol.active === 2;

    if (isEnabled && !isWebsocket) {
      Utils.DLog('[Chat]', 'Stream Management is disabled for BOSH');
    }

    return isEnabled && isWebsocket;
  }

  private addXMPPClientListener(name: Chat.XMPPClientEvent, listener: Chat.XMPPClientListener): void {
    this.xmppClient.on(name, listener);
    this.xmppClientListeners.set(name, listener);
  }

  private removeXMPPClientListener(name: Chat.XMPPClientEvent): void {
    const listener = this.xmppClientListeners.get(name);
    if (listener) {
      this.xmppClient.removeListener(name, listener);
    }
    this.xmppClientListeners.delete(name);
  }

  private removeAllXMPPClientListeners(): void {
    this.xmppClientListeners.forEach((_, name) => this.removeXMPPClientListener(name));
  }

  private getListenerByName(name: ChatEvent): string | null {
    switch (name) {
      case ChatEvent.STATUS:
        return 'onChatStatusListener';
      case ChatEvent.ERROR:
        return 'onConnectionErrorListener';
      case ChatEvent.DISCONNECTED:
        return 'onDisconnectedListener';
      case ChatEvent.RECONNECTED:
        return 'onReconnectListener';
      case ChatEvent.MESSAGE:
        return 'onMessageListener';
      case ChatEvent.SYSTEM_MESSAGE:
        return 'onSystemMessageListener';
      case ChatEvent.ERROR_MESSAGE:
        return 'onMessageErrorListener';
      case ChatEvent.TYPING_MESSAGE:
        return 'onMessageTypingListener';
      case ChatEvent.UPDATE_MESSAGE:
        return 'onMessageUpdateListener';
      case ChatEvent.DELETE_MESSAGE:
        return 'onMessageDeleteListener';
      case ChatEvent.REACTIONS_MESSAGE:
        return 'onMessageReactionsListener';
      case ChatEvent.DELIVERED_MESSAGE:
        return 'onDeliveredStatusListener';
      case ChatEvent.READ_MESSAGE:
        return 'onReadStatusListener';
      case ChatEvent.SENT_MESSAGE:
        return 'onSentMessageCallback';
      case ChatEvent.USER_LAST_ACTIVITY:
        return 'onLastUserActivityListener';
      case ChatEvent.ROSTER_SUBSCRIBE:
        return 'onSubscribeListener';
      case ChatEvent.ROSTER_CONFIRM:
        return 'onConfirmSubscribeListener';
      case ChatEvent.ROSTER_REJECT:
        return 'onRejectSubscribeListener';
      case ChatEvent.ROSTER_LIST:
        return 'onContactListListener';
      case ChatEvent.JOIN:
        return 'onJoinOccupant';
      case ChatEvent.LEAVE:
        return 'onLeaveOccupant';
      case ChatEvent.KICK:
        return 'onKickOccupant';
      default:
        return null;
    }
  }

  public addListener(name: ChatEvent, listener: Chat.Listeners): () => void {
    const listenerName = this.getListenerByName(name);
    if (listenerName) {
      this[listenerName] = listener;
    }
    return this.removeListener.bind(this, name);
  }

  public removeListener(name: ChatEvent): void {
    const listenerName = this.getListenerByName(name);
    if (listenerName) {
      this[listenerName] = undefined;
    }
  }

  public removeAllListeners(): void {
    Object.keys(this).forEach((key) => {
      if (key.startsWith('on') && key.endsWith('Listener') && typeof this[key] === 'function') {
        this[key] = undefined;
      }
    });
  }
}
