import ProxyService from '../Proxy';
import Utils from '../Utils';
import config from '../config';
import { Dialogs, EmptyResponse, ForceParams } from '../types';
import { ProxyMethod, ProxyType } from '../types/proxy';

export default class DialogsService {
  public proxy: ProxyService;
  readonly route = `${config.urls.chat}/Dialog`;

  constructor(proxy: ProxyService) {
    this.proxy = proxy;
  }

  public async list(params: Dialogs.ListParams = {}): Promise<Dialogs.ListResponse> {
    const ajaxParams = {
      type: ProxyMethod.GET,
      url: Utils.getUrl(this.route),
      data: params,
    };

    return this.proxy.ajax(ajaxParams);
  }

  public async create(params: Dialogs.CreateParams): Promise<Dialogs.Dialog> {
    const data = Utils.cloneObject(params);

    if (Utils.isArray(data?.occupants_ids)) {
      data.occupants_ids = data.occupants_ids.join(', ');
    }

    if (Utils.isArray(data?.admins_ids)) {
      data.admins_ids = data.admins_ids.join(', ');
    }

    const ajaxParams = {
      type: ProxyMethod.POST,
      url: Utils.getUrl(this.route),
      data: data,
    };

    return this.proxy.ajax(ajaxParams);
  }

  public async update(id: string, params: Dialogs.UpdateParams = {}): Promise<Dialogs.Dialog> {
    const ajaxParams = {
      type: ProxyMethod.PUT,
      url: Utils.getUrl(this.route, id),
      data: params,
    };

    return this.proxy.ajax(ajaxParams);
  }

  public async delete(
    idOrIds: string | string[],
    params: ForceParams = {}
  ): Promise<Dialogs.DeleteResponse | EmptyResponse> {
    const idsToDelete = typeof idOrIds === 'string' ? idOrIds.split(',').map((id) => id.trim()) : idOrIds;
    const ajaxParams = {
      type: ProxyMethod.DELETE,
      url: Utils.getUrl(this.route, idsToDelete.toString()),
      dataType: idsToDelete.length === 1 ? ProxyType.TEXT : ProxyType.JSON,
      data: params,
    };

    return this.proxy.ajax(ajaxParams);
  }

  public async addAdmins(id: string, admins_ids: number[]): Promise<Dialogs.Dialog> {
    const ajaxParams = {
      type: ProxyMethod.PUT,
      url: Utils.getUrl(this.route, id, 'admins'),
      data: { push_all: { admins_ids } },
    };

    return this.proxy.ajax(ajaxParams);
  }

  public async removeAdmins(id: string, admins_ids: number[]): Promise<Dialogs.Dialog> {
    const ajaxParams = {
      type: ProxyMethod.PUT,
      url: Utils.getUrl(this.route, id, 'admins'),
      data: { pull_all: { admins_ids } },
    };

    return this.proxy.ajax(ajaxParams);
  }

  public async subscribe(id: string): Promise<Dialogs.Dialog> {
    const ajaxParams = {
      type: ProxyMethod.POST,
      url: Utils.getUrl(this.route, id, 'subscribe'),
    };

    return this.proxy.ajax(ajaxParams);
  }

  public subscribeToPublic = this.subscribe;

  public async unsubscribe(id: string): Promise<EmptyResponse> {
    const ajaxParams = {
      type: ProxyMethod.DELETE,
      url: Utils.getUrl(this.route, id, 'subscribe'),
      dataType: ProxyType.TEXT,
    };

    return this.proxy.ajax(ajaxParams);
  }

  public unsubscribeFromPublic = this.unsubscribe;

  public async updateNotificationsSettings(
    id: string,
    enabled: number | boolean
  ): Promise<Dialogs.UpdateNotificationsSettingsResponse> {
    const ajaxParams = {
      type: ProxyMethod.PUT,
      url: Utils.getUrl(this.route, id, 'notifications'),
      data: { enabled: enabled ? 1 : 0 },
    };

    return this.proxy.ajax(ajaxParams);
  }

  public async getNotificationsSettings(id: string): Promise<Dialogs.GetNotificationsSettingsResponse> {
    const ajaxParams = {
      type: ProxyMethod.GET,
      url: Utils.getUrl(this.route, id, 'notifications'),
    };

    return this.proxy.ajax(ajaxParams);
  }

  public async getPublicOccupants(
    id: string,
    params: Dialogs.GetPublicOccupantsParams = {}
  ): Promise<Dialogs.GetPublicOccupantsResponse> {
    const ajaxParams = {
      type: ProxyMethod.GET,
      url: Utils.getUrl(this.route, id, 'occupants'),
      data: params,
    };

    return this.proxy.ajax(ajaxParams);
  }

  public async clearHistory(id: string): Promise<EmptyResponse> {
    const ajaxParams = {
      type: ProxyMethod.DELETE,
      url: Utils.getUrl(this.route, 'clearHistory', id),
      dataType: ProxyType.TEXT,
    };

    return this.proxy.ajax(ajaxParams);
  }
}
