import ChatUtils from './ChatUtils';
import Utils from '../Utils';
import ChatHelpers from './Helpers';
import { XMPPClient } from './xmpp';
import { Chat } from '../types';

export default class PrivacyList {
  readonly xmlns = 'jabber:iq:privacy';
  public helpers: ChatHelpers;
  public xmppClient: XMPPClient;
  public stanzasCallbacks: Chat.StanzasCallbacks;

  constructor(options: { helpers: ChatHelpers; xmppClient: XMPPClient; stanzasCallbacks: Chat.StanzasCallbacks }) {
    this.helpers = options.helpers;
    this.xmppClient = options.xmppClient;
    this.stanzasCallbacks = options.stanzasCallbacks;
  }

  public async create(list: Chat.PrivacyList): Promise<void> {
    return new Promise((resolve, reject) => {
      const privacyList = list.items.reduce((acc, { user_id, action, mutualBlock }) => {
        acc[user_id] = { action, mutualBlock };
        return acc;
      }, {});

      const privacyListKeys = Object.keys(privacyList);
      const iqParams = {
        type: 'set',
        from: this.helpers.userCurrentJid,
        id: ChatUtils.getUniqueId('edit'),
      };

      let iq = ChatUtils.createIqStanza(iqParams);

      iq.c('query', { xmlns: this.xmlns }).c('list', { name: list.name });

      for (let index = 0, j = 0, len = privacyListKeys.length; index < len; index++, j = j + 2) {
        const userId = privacyListKeys[index];
        const { action, mutualBlock } = privacyList[userId];
        const isMutual = mutualBlock && action === 'deny';
        const userJid = this.helpers.jidOrUserId(parseInt(userId, 10));
        const userMuc = this.helpers.getUserNickWithMucDomain(userId);

        this.assignPrivacyItem(iq, { order: j + 1, value: userJid, action, isMutual });
        this.assignPrivacyItem(iq, { order: j + 2, value: userMuc, action, isMutual });
      }

      this.stanzasCallbacks[iqParams.id] = (stanza: Chat.XmlElement) => {
        ChatUtils.isErrorStanza(stanza) ? reject(ChatUtils.buildErrorFromXMPPErrorStanza(stanza)) : resolve();
      };

      this.xmppClient.sendOnline(iq);
    });
  }

  public async getList(name: string): Promise<Chat.PrivacyList> {
    return new Promise((resolve, _reject) => {
      const id = ChatUtils.getUniqueId('getlist');
      const iq = ChatUtils.createIqStanza({
        type: 'get',
        from: this.helpers.userCurrentJid,
        id,
      });

      iq.c('query', { xmlns: this.xmlns }).c('list', { name });

      this.stanzasCallbacks[id] = (stanza: Chat.XmlElement): void => {
        const query = ChatUtils.getElement(stanza, 'query');
        const list = ChatUtils.getElement(query, 'list');
        const privacyList: Chat.PrivacyList = {
          name: ChatUtils.getAttr(list, 'name'),
          items: ChatUtils.getChildElements(list)
            .map((item, index) => {
              if (index % 2 === 0) {
                const { action, value } = item.attrs;
                return {
                  user_id: this.helpers.getUserIdFromJID(value),
                  action: action,
                };
              }
              return null;
            })
            .filter((item) => item !== null),
        };

        resolve(privacyList);

        delete this.stanzasCallbacks[id];
      };

      this.xmppClient.sendOnline(iq);
    });
  }

  public async update(list: Chat.PrivacyList): Promise<void> {
    try {
      const existentList = await this.getList(list.name);
      const updatedList = {
        items: Utils.mergeArrays(existentList.items, list.items),
        name: list.name,
      };
      await this.create(updatedList);
    } catch (error) {
      throw error;
    }
  }

  public async getNames(): Promise<Chat.PrivacyListNames> {
    return new Promise((resolve, reject) => {
      const id = ChatUtils.getUniqueId('getNames');
      const iq = ChatUtils.createIqStanza({
        type: 'get',
        from: this.helpers.userCurrentJid,
        id,
      });

      iq.c('query', { xmlns: this.xmlns });

      this.stanzasCallbacks[id] = (stanza: Chat.XmlElement) => {
        if (!ChatUtils.isErrorStanza(stanza)) {
          const query = ChatUtils.getElement(stanza, 'query');
          const defaultElement = ChatUtils.getElement(query, 'default');
          const activeElement = ChatUtils.getElement(query, 'active');
          const defaultName = ChatUtils.getAttr(defaultElement, 'name');
          const activeName = ChatUtils.getAttr(activeElement, 'name');
          const allNames = ChatUtils.getChildElements(query).map((list) => ChatUtils.getAttr(list, 'name'));

          const namesList = {
            default: defaultName,
            active: activeName,
            names: allNames,
          };

          resolve(namesList);
        } else {
          reject(ChatUtils.buildErrorFromXMPPErrorStanza(stanza));
        }
      };

      this.xmppClient.sendOnline(iq);
    });
  }

  public async delete(name: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const id = ChatUtils.getUniqueId('remove');
      const iq = ChatUtils.createIqStanza({
        type: 'set',
        from: this.helpers.userCurrentJid,
        id,
      });

      iq.c('query', { xmlns: this.xmlns }).c('list', { name: name ? name : '' });

      this.stanzasCallbacks[id] = (stanza: Chat.XmlElement) => {
        ChatUtils.isErrorStanza(stanza) ? reject(ChatUtils.buildErrorFromXMPPErrorStanza(stanza)) : resolve();
      };

      this.xmppClient.sendOnline(iq);
    });
  }

  public async setAsDefault(name: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const id = ChatUtils.getUniqueId('default');
      const iq = ChatUtils.createIqStanza({
        type: 'set',
        from: this.helpers.userCurrentJid,
        id,
      });

      iq.c('query', { xmlns: this.xmlns }).c('default', name && name.length > 0 ? { name: name } : {});

      this.stanzasCallbacks[id] = (stanza: Chat.XmlElement) => {
        ChatUtils.isErrorStanza(stanza) ? reject(ChatUtils.buildErrorFromXMPPErrorStanza(stanza)) : resolve();
      };

      this.xmppClient.sendOnline(iq);
    });
  }

  private assignPrivacyItem(iq: Chat.XmlElement, params: Chat.PrivacyListItem): void {
    const { value, action, order, isMutual } = params;
    const query = ChatUtils.getElement(iq, 'query');
    const list = ChatUtils.getElement(query, 'list');
    const item = list.c('item', { type: 'jid', value, action, order });

    if (isMutual) {
      item.up();

      if (order % 2 === 0) {
        iq.up().up();
      }
    } else {
      item.c('message', {}).up().c('presence-in', {}).up().c('presence-out', {}).up().c('iq', {}).up().up();
    }
  }
}
