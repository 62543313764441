import { XMPPClient } from './xmpp';
import ChatUtils from './ChatUtils';
import Utils from '../Utils';
import { Chat } from '../types';

export default class StreamManagement {
  readonly xmlns: string = 'urn:xmpp:sm:3';
  private enabled: boolean = false;
  private clientProcessedStanzasCounter: number = 0; // Counter of the incoming stanzas
  private clientSentStanzasCounter: number = 0; // The client send stanza counter.
  private lastAck: number = 0; // The last acknowledged stanza
  private unackedQueue: { message: object; expect: number }[] = []; // In progress stanzas queue
  private xmppClient!: XMPPClient; // connection
  private originalSend!: XMPPClient['sendOnline']; // Original connection.sendOnline method
  public sentMessageCallback!: Chat.OnMessageSentListener;

  public enable(connection: XMPPClient): void {
    if (!this.enabled) {
      this.xmppClient = connection;
      this.originalSend = this.xmppClient.sendOnline;
      this.xmppClient.sendOnline = this.sendAndCount.bind(this);
    }

    this.clientProcessedStanzasCounter = 0;
    this.clientSentStanzasCounter = 0;
    this.lastAck = 0;

    this.removeElementHandler();
    this.addElementHandler();

    const stanza = ChatUtils.createNonza('enable', { xmlns: this.xmlns });

    this.xmppClient.sendOnline(stanza);
  }

  public removeElementHandler(): void {
    this.xmppClient.removeListener('element', this.incomingStanzaHandler);
  }

  public addElementHandler(): void {
    this.xmppClient.on('element', this.incomingStanzaHandler);
  }

  private async sendAndCount(stanza: Chat.XmlElement, message?: object): Promise<void> {
    const type = ChatUtils.getAttr(stanza, 'type');
    const body = ChatUtils.getElementText(stanza, 'body');
    const attachments = ChatUtils.getAllElements(stanza, 'attachment');
    const isMessage = stanza.name === 'message';
    const isChat = type === 'chat' || type === 'groupchat';
    const isContent = body || attachments.length;

    this.originalSend.call(this.xmppClient, stanza);

    if (this.enabled && isMessage && isChat && isContent) {
      this.unackedQueue.push({ message, expect: this.clientSentStanzasCounter });

      const r = ChatUtils.createNonza('r', { xmlns: this.xmlns });

      this.originalSend.call(this.xmppClient, r);
    }

    ++this.clientSentStanzasCounter;
  }

  private incomingStanzaHandler = (stanza: Chat.XmlElement): void => {
    if (stanza.name === 'enabled') {
      this.enabled = true;
      return;
    }

    if (ChatUtils.getAttr(stanza, 'xmlns') !== this.xmlns) {
      ++this.clientProcessedStanzasCounter;
    }

    if (stanza.name === 'r') {
      const params = { xmlns: this.xmlns, h: this.clientProcessedStanzasCounter };
      const answerStanza = ChatUtils.createNonza('a', params);
      this.originalSend.call(this.xmppClient, answerStanza);
      return;
    }

    if (stanza.name === 'a') {
      const h = parseInt(ChatUtils.getAttr(stanza, 'h'));
      const numAcked = h - this.lastAck;

      Utils.DLog('[Chat][SM][checkCounterOnIncomeStanza]', numAcked, h, this.lastAck);

      for (let i = 0; i < numAcked && this.unackedQueue.length > 0; i++) {
        this.sentMessageCallback(null, this.unackedQueue.shift().message);
      }

      this.lastAck = h;
    }
  };
}
