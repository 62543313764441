import ProxyService from './Proxy';
import Utils from './Utils';
import config from './config';
import { Auth, Proxy, Users } from './types';
import { ProxyMethod, ProxyType } from './types/proxy';
import { EmptyParams, EmptyResponse } from './types/common';

export default class AuthService {
  public proxy: ProxyService;

  readonly routes = {
    session: config.urls.session,
    login: config.urls.login,
  };

  constructor(proxy: ProxyService) {
    this.proxy = proxy;
  }

  public setSession = (session: Proxy.SDKInstance['session']): void => {
    this.proxy.setSession(session);
  };

  public getSession = async (): Promise<Auth.Session> => {
    try {
      const ajaxParams = {
        type: ProxyMethod.GET,
        url: Utils.getUrl(this.routes.session),
      };
      const response = await this.proxy.ajax<Auth.GetSessionRawResponse>(ajaxParams);
      return response.session;
    } catch (error) {
      throw error;
    }
  };

  public createSession = async (params: Auth.LoginParams | EmptyParams = {}): Promise<Auth.Session> => {
    if (config.creds.appId === '' || config.creds.authKey === '') {
      throw new Error('Cannot create a new session without "appId" and "authKey"');
    }

    try {
      const ajaxParams = {
        type: ProxyMethod.POST,
        url: Utils.getUrl(this.routes.session),
        data: this.generateCreateSessionParams(params),
      };
      const response = await this.proxy.ajax<Auth.GetSessionRawResponse>(ajaxParams);
      this.proxy.setSession(response.session);
      this.proxy.setCurrentUserId(response.session.user_id);
      return response.session;
    } catch (error) {
      throw error;
    }
  };

  public destroySession = async (): Promise<EmptyResponse> => {
    try {
      const ajaxParams = {
        type: ProxyMethod.DELETE,
        url: Utils.getUrl(this.routes.session),
        dataType: ProxyType.TEXT,
      };
      await this.proxy.ajax(ajaxParams);
      this.proxy.setSession(null);
      this.proxy.setCurrentUserId(null);
    } catch (error) {
      throw error;
    }
  };

  public login = async (params: Auth.LoginParams): Promise<Users.User> => {
    try {
      const ajaxParams = {
        type: ProxyMethod.POST,
        url: Utils.getUrl(this.routes.login),
        data: params,
      };
      const { user } = await this.proxy.ajax<Auth.LoginRawResponse>(ajaxParams);
      this.proxy.setCurrentUserId(user.id);
      return user;
    } catch (error) {
      throw error;
    }
  };

  public logout = async (): Promise<EmptyResponse> => {
    const ajaxParams = {
      type: ProxyMethod.DELETE,
      url: Utils.getUrl(this.routes.login),
      dataType: ProxyType.TEXT,
    };
    this.proxy.setCurrentUserId(null);
    return this.proxy.ajax(ajaxParams);
  };

  private generateCreateSessionParams(params: Auth.LoginParams | EmptyParams = {}): Auth.GeneratedSessionParams {
    const message: any = {
      application_id: config.creds.appId,
      auth_key: config.creds.authKey,
    };

    if ('guest' in params) {
      message.user = params;
    } else if ('login' in params && 'password' in params) {
      message.user = { login: params.login, password: params.password };
    } else if ('email' in params && 'password' in params) {
      message.user = { email: params.email, password: params.password };
    } else if ('provider' in params) {
      message.provider = params.provider;
      if ('keys' in params) {
        message.keys = params.keys;
      }
      if ('firebase_phone' in params) {
        message.firebase_phone = params.firebase_phone;
      }
      if ('firebase_email' in params) {
        message.firebase_email = params.firebase_email;
      }
    }

    return message;
  }
}
