import { base64Encode } from './platform';
import config from './config';
import ProxyService from './Proxy';
import Utils from './Utils';
import { EmptyResponse, PushNotifications } from './types';
import { ProxyMethod, ProxyType } from './types/proxy';

export default class PushNotificationsService {
  public proxy: ProxyService;
  public subscriptions: SubscriptionsService;
  public events: EventsService;

  constructor(proxy: ProxyService) {
    this.proxy = proxy;
    this.subscriptions = new SubscriptionsService(proxy);
    this.events = new EventsService(proxy);
  }

  public base64Encode = base64Encode;
}

class SubscriptionsService {
  public proxy: ProxyService;
  readonly route = config.urls.subscriptions;

  constructor(proxy: ProxyService) {
    this.proxy = proxy;
  }

  public async create(params: PushNotifications.SubscriptionsCreateParams): Promise<PushNotifications.Subscription> {
    const ajaxParams = {
      type: ProxyMethod.POST,
      url: Utils.getUrl(this.route),
      data: params,
    };

    return this.proxy.ajax(ajaxParams);
  }

  public async list(): Promise<PushNotifications.Subscription[]> {
    const ajaxParams = {
      type: ProxyMethod.GET,
      url: Utils.getUrl(this.route),
    };

    return this.proxy.ajax(ajaxParams);
  }

  public async delete(id: string | number): Promise<EmptyResponse> {
    const ajaxParams = {
      type: ProxyMethod.DELETE,
      dataType: ProxyType.TEXT,
      url: Utils.getUrl(this.route, id),
    };

    return this.proxy.ajax(ajaxParams);
  }
}

class EventsService {
  public proxy: ProxyService;
  readonly route = config.urls.events;

  constructor(proxy: ProxyService) {
    this.proxy = proxy;
  }

  public async create(event: PushNotifications.EventsCreateParams): Promise<PushNotifications.Event[]> {
    const ajaxParams = {
      type: ProxyMethod.POST,
      url: Utils.getUrl(this.route),
      data: { event },
    };

    return this.proxy.ajax(ajaxParams);
  }
}
