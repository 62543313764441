import { mediaDevices } from '../platform';
import Utils from '../Utils';
import JanusClient from './JanusClient';
import { Auth, Janus, Media } from '../types';
import { CallType, JanusEvent } from '../types/janus';

export default class ConferenceSession {
  private client: JanusClient;
  public id: string = `${Math.random()}`;
  public currentUserDisplayName?: string;
  public localStream?: MediaStream;
  public mediaParams: MediaStreamConstraints = {};
  public onParticipantJoinedListener!: Janus.OnParticipantJoinedListener;
  public onParticipantLeftListener!: Janus.OnParticipantLeftListener;
  public onSlowLinkListener!: Janus.OnSlowLinkListener;
  public onRemoteStreamListener!: Janus.OnRemoteStreamListener;
  public onRemoteTracksUpdatedListener!: Janus.OnRemoteTracksUpdatedListener;
  public onRemoteConnectionStateChangedListener!: Janus.OnRemoteConnectionStateChangedListener;
  public onDataChannelOpenedListener!: Janus.OnDataChannelOpenedListener;
  public onDataChannelMessageListener!: Janus.OnDataChannelMessageListener;
  public onSessionConnectionStateChangedListener!: Janus.OnSessionConnectionStateChangedListener;
  public onErrorListener!: Janus.OnErrorListener;

  constructor(token: Auth.Session['token']) {
    this.client = new JanusClient(token);
    this.client.on(JanusEvent.PARTICIPANT_JOINED, this.onParticipantJoined);
    this.client.on(JanusEvent.PARTICIPANT_LEFT, this.onParticipantLeft);
    this.client.on(JanusEvent.REMOTE_STREAM, this.onRemoteStream);
    this.client.on(JanusEvent.REMOTE_TRACKS_UPDATED, this.onRemoteTracksUpdated);
    this.client.on(JanusEvent.DATA_CHANNEL_OPEN, this.onDataChannelOpen);
    this.client.on(JanusEvent.DATA_CHANNEL_MESSAGE, this.onDataChannelMessage);
    this.client.on(JanusEvent.ERROR, this.onError);
  }

  get currentRoomId(): string | null {
    return this.client.currentRoomId;
  }

  set currentRoomId(roomId: string | null) {
    this.client.currentRoomId = roomId;
  }

  get currentPublisherPC() {
    return this.client.videoRoomPlugin.webrtcStuff.pc;
  }

  private async createSession(): Promise<void> {
    let isResolved = false;

    return new Promise((resolve, reject) => {
      this.client.createSession({
        success: () => {
          isResolved = true;
          resolve(void 0);
        },
        error: (error) => {
          if (isResolved) {
            this.onError(error);
          } else {
            reject(error);
          }
        },
      });
    });
  }

  // joinAsPublisher+Listener
  public async join(roomId: string, user_id: number, userDisplayName: string): Promise<void> {
    this.currentUserDisplayName = userDisplayName;
    this.currentRoomId = roomId;
    await this.createSession();
    await this.createHandler(user_id, false, false);
    await this.joinInternal(this.currentRoomId, user_id);
  }

  public async joinAsListener(roomId: string, user_id: number, userDisplayName: string): Promise<void> {
    this.currentUserDisplayName = userDisplayName;
    this.currentRoomId = roomId;
    await this.createSession();
    await this.createHandler(user_id, false, true);
    await this.joinInternal(this.currentRoomId, user_id);
  }

  public async sendKeyframeRequest(roomId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.client.sendKeyframeRequest(roomId, {
        success: resolve,
        error: reject,
      });
    });
  }

  private async createHandler(user_id: number, isRemote: boolean, skipMedia: boolean = false): Promise<void> {
    return new Promise((resolve, reject) => {
      this.client.attachVideoConferencingPlugin(isRemote, user_id, skipMedia, {
        success: resolve,
        error: reject,
        iceState: isRemote
          ? (iceState) => this.onRemoteIceStateChanged(user_id, iceState)
          : (iceState) => this.onLocalIceStateChanged(iceState),
        slowLink: isRemote
          ? (uplink, nacks) => this.onSlowLink(user_id, uplink, nacks)
          : (uplink, nacks) => this.onSlowLink(null, uplink, nacks),
        localStream: this.localStream,
        displayName: this.currentUserDisplayName,
      });
    });
  }

  private async joinInternal(roomId: string, user_id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.client.join(roomId, user_id, false, {
        success: resolve,
        error: reject,
        displayName: this.currentUserDisplayName,
      });
    });
  }

  private onParticipantJoined = (user_id: number, userDisplayName: string, isExistingParticipant: boolean): void => {
    Utils.DLog('[onParticipantJoined]', user_id, userDisplayName, isExistingParticipant);
    this.createHandler(user_id, true, false);
    Utils.safeCallbackCall(this.onParticipantJoinedListener)(this, user_id, userDisplayName, isExistingParticipant);
  };

  private onParticipantLeft = (user_id: number, userDisplayName: string): void => {
    Utils.DLog('[onParticipantLeft]', user_id, userDisplayName);
    Utils.safeCallbackCall(this.onParticipantLeftListener)(this, user_id, userDisplayName);
  };

  private onError = (error: any): void => {
    Utils.DLog('[onError]', JSON.stringify(error));
    Utils.safeCallbackCall(this.onErrorListener)(this, error);
  };

  private onDataChannelOpen = (label): void => {
    Utils.DLog('[onDataChannelOpen]', label);
    Utils.safeCallbackCall(this.onDataChannelOpenedListener)(this, label);
  };

  private onDataChannelMessage = (user_id: number, data): void => {
    Utils.DLog('[onDataChannelMessage]', user_id, data);
    Utils.safeCallbackCall(this.onDataChannelMessageListener)(this, user_id, data);
  };

  private onLocalIceStateChanged = (iceState): void => {
    Utils.DLog('[onLocalIceStateChanged]', iceState);
    Utils.safeCallbackCall(this.onSessionConnectionStateChangedListener)(this, iceState);
  };

  private onRemoteIceStateChanged = (user_id: number, iceState): void => {
    Utils.DLog('[onRemoteIceStateChanged]', user_id, iceState);
    Utils.safeCallbackCall(this.onRemoteConnectionStateChangedListener)(this, user_id, iceState);
  };

  private onRemoteStream = (user_id: number, stream: MediaStream): void => {
    Utils.DLog('[onRemoteStream]', user_id, stream);
    Utils.safeCallbackCall(this.onRemoteStreamListener)(this, user_id, stream);
  };

  private onRemoteTracksUpdated = (user_id: number, track: MediaStreamTrack, eventType): void => {
    Utils.DLog('[onRemoteTracksUpdated]', user_id, track, eventType);
    Utils.safeCallbackCall(this.onRemoteTracksUpdatedListener)(this, user_id, track, eventType);
  };

  private onSlowLink = (user_id: number | null, uplink, nacks): void => {
    Utils.DLog('[onSlowLink]', user_id, uplink, nacks);
    Utils.safeCallbackCall(this.onSlowLinkListener)(this, user_id, uplink, nacks);
  };

  public async listOfOnlineParticipants(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.currentRoomId) {
        this.client.listOnlineParticipants(this.currentRoomId, {
          success: resolve,
          error: reject,
        });
      } else {
        reject('Room ID is not set');
      }
    });
  }

  public async leave(): Promise<void> {
    this.currentRoomId = null;
    this.currentUserDisplayName = undefined;
    await this.leaveGroup();
    await this.detachVideoConferencingPlugin();
    await this.destroy();

    if (this.localStream) {
      this.localStream.getTracks().forEach((track) => track.stop());
      this.localStream = undefined;
      this.mediaParams = {};
    }
  }

  public async leaveGroup(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.client.leave({
        success: resolve,
        error: reject,
      });
    });
  }

  public async destroy(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.client.destroySession({
        success: resolve,
        error: reject,
      });
    });
  }

  public async detachVideoConferencingPlugin(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.client.detachVideoConferencingPlugin({
        success: resolve,
        error: reject,
      });
    });
  }

  public async getDisplayMedia(params: Media.DisplayParams): Promise<MediaStream> {
    if (!mediaDevices.getDisplayMedia) {
      throw new Error("Your environment does not support 'getDisplayMedia' API");
    }

    const elementId = params && params.elementId;
    const options = params && params.options;
    const mediaParams = { ...params };

    this.mediaParams = mediaParams;
    delete mediaParams.elementId;
    delete mediaParams.options;

    try {
      const stream = await mediaDevices.getDisplayMedia(mediaParams);
      return this.upsertStream(stream, elementId, options);
    } catch (error) {
      throw error;
    }
  }

  public async getUserMedia(params: Media.UserParams): Promise<MediaStream> {
    const elementId = params && params.elementId;
    const options = params && params.options;
    const mediaParams = { ...params };

    this.mediaParams = mediaParams;
    delete mediaParams.elementId;
    delete mediaParams.options;

    try {
      const stream = await mediaDevices.getUserMedia(mediaParams);
      return this.upsertStream(stream, elementId, options);
    } catch (error) {
      throw error;
    }
  }

  private upsertStream(
    stream: MediaStream,
    elementId: string | null = null,
    options: Media.ElementOptions = {}
  ): MediaStream {
    const shouldUpdateCurrentStream = !!this.localStream;

    if (shouldUpdateCurrentStream) {
      this.replaceTracks(stream);
    } else {
      this.localStream = stream;
    }

    if (!this.localStream) {
      throw new Error('Local stream is not defined');
    }

    if (elementId) {
      if (shouldUpdateCurrentStream) {
        this.detachMediaStream(elementId, options);
      }
      this.attachMediaStream(elementId, this.localStream, options);
    }

    return this.localStream;
  }

  private replaceTracks(stream: MediaStream): MediaStream {
    this.localStream?.getTracks().forEach((localTrack) => {
      if (localTrack.kind === CallType.AUDIO && stream.getAudioTracks().length === 0) {
        return;
      } else {
        localTrack.stop();
        this.localStream?.removeTrack(localTrack);
      }
    });

    stream.getTracks().forEach((newTrack) => {
      const pcSenders = this.currentPublisherPC.getSenders();
      const sender = pcSenders.find(({ track }) => newTrack.kind === track.kind);

      if (newTrack.kind === CallType.AUDIO) {
        newTrack.enabled = this.localStream?.getAudioTracks().every(({ enabled }) => enabled) ?? true;
      } else {
        newTrack.enabled = this.localStream?.getVideoTracks().every(({ enabled }) => enabled) ?? true;
      }

      if (sender) {
        sender.replaceTrack(newTrack);
        this.localStream?.addTrack(newTrack);
      } else {
        console.warn(`No sender found for track kind: ${newTrack.kind}`);
      }
    });

    if (!this.localStream) {
      throw new Error('Local stream is not defined');
    }

    return this.localStream;
  }

  public async switchMediaTracks(options: Media.TrackConstraintsOrDeviceIds): Promise<MediaStream> {
    [CallType.AUDIO, CallType.VIDEO].forEach((type) => {
      const device = type === CallType.AUDIO ? options.audio : type === CallType.VIDEO ? options.video : {};
      const deviceId = typeof device === 'string' ? device : (device?.deviceId ?? null);

      if (deviceId) {
        if (typeof this.mediaParams[type] === 'object') {
          this.mediaParams[type].deviceId = deviceId;
        } else {
          this.mediaParams[type] = { deviceId };
        }
      }
    });

    try {
      const mediaParams = {
        audio: this.mediaParams?.audio ?? false,
        video: this.mediaParams?.video ?? false,
      };
      const stream = await mediaDevices.getDisplayMedia(mediaParams);
      return this.replaceTracks(stream);
    } catch (error) {
      throw error;
    }
  }

  public muteVideo(): void {
    if (!this.isVideoMuted()) {
      this.client.toggleVideoMute();
    }
  }

  public unmuteVideo(): void {
    if (this.isVideoMuted()) {
      this.client.toggleVideoMute();
    }
  }

  public muteAudio(): void {
    if (!this.isAudioMuted()) {
      this.client.toggleAudioMute();
    }
  }

  public unmuteAudio(): void {
    if (this.isAudioMuted()) {
      this.client.toggleAudioMute();
    }
  }

  public isVideoMuted(): boolean {
    return this.client.isVideoMuted();
  }

  public isAudioMuted(): boolean {
    return this.client.isAudioMuted();
  }

  public async getUserVolume(): Promise<any> {
    return new Promise((resolve, _reject) => {
      return this.client.getVolume(resolve);
    });
  }

  public getRemoteUserBitrate(userId: number): any {
    return this.client.getUserBitrate(userId);
  }

  public async getRemoteUserVolume(userId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.client.getUserVolume(userId, resolve);
    });
  }

  public attachMediaStream(elementId: string, stream: MediaStream, opt?: Media.ElementOptions): void {
    const mediaElement = document.getElementById(elementId) as HTMLMediaElement;

    if ('srcObject' in mediaElement) {
      mediaElement.srcObject = stream;
      mediaElement.style.transform = opt?.mirror ? 'scaleX(-1)' : 'none';
      if (typeof opt?.muted === 'boolean') {
        mediaElement.muted = opt?.muted;
      }
      mediaElement.onloadedmetadata = (_event: Event) => {
        mediaElement.play();
      };
    } else {
      throw new Error(`Unable to attach media stream, element #${elementId} is undefined`);
    }
  }

  public detachMediaStream(elementId: string, opt?: Media.ElementOptions) {
    const mediaElement = document.getElementById(elementId) as HTMLMediaElement;

    if ('srcObject' in mediaElement) {
      mediaElement.pause();
      mediaElement.srcObject = null;
      mediaElement.style.transform = opt?.mirror ? 'scaleX(-1)' : 'none';
      if (typeof opt?.muted === 'boolean') {
        mediaElement.muted = opt?.muted;
      }
    } else {
      throw new Error(`Unable to attach media stream, element #${elementId} is undefined`);
    }
  }

  public async sendData(data: any, label: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.client.videoRoomPlugin.data({
        data,
        label,
        success: resolve,
        error: reject,
      });
    });
  }
}
